.colourSelectDropdown {
  width: min-content !important;
  min-width: min-content !important;
}

.colourSelectorContainer {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;

  .colourSelector {
    border-radius: 50%;
  }

  .colourSelector {
    border-radius: 50%;
  }
}

.classNotes {
  height: 100vh;
  margin-left: auto;
  margin-right: 0;

  .titleBar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;

    h1,
    h2 {
      margin-bottom: 0;
      line-height: 1;
    }

    .functionBar {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;
      margin-bottom: 2rem;

      p {
        margin-bottom: 0;
      }
    }

    .anticon:not(.colourSelector) {
      padding-right: 1rem;
    }

    .ant-alert-content {
      flex-direction: row;
      display: flex;

      button {
        margin-left: 1rem;
      }
    }
  }

  .ant-divider-horizontal {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  .sideTitle {
    font-size: 2.2rem;
    margin-bottom: 0.5em;
    font-weight: bold;

    .anticon {
      padding-right: 1rem;
    }
  }

  .ant-drawer-body {
    display: flex;
    flex-direction: column;
  }

  .mainBar {
    max-height: 85%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    gap: 2rem;

    .notesContainer {
      margin-right: 2rem;
      min-height: 30rem;

      .quill {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow-y: auto;

        .ql-container {
          overflow-y: auto;
        }
      }

      .ql-toolbar {
        text-align: center;
      }
    }

    .starterContainer {
      .saves {
        flex-grow: 1;
        width: 100%;
        border: none;

        .droppableArea {
          background-color: inherit;
          border: none;
          border-radius: 0;
        }

        .day-dragable-container {
          grid-auto-flow: row;
          grid-template-rows: unset;
          grid-template-columns: repeat(3, 1fr);
          width: 100%;
        }
      }
    }

    .ql-toolbar,
    .ql-container,
    .saves {
      border: solid $gray-5 1px;
    }

    .starterContainer,
    .notesContainer {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }
  }

  .ant-drawer-footer {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
  }
}

.tagsPopover {
  .ant-popover-title {
    min-width: 0;
  }

  .ant-popover-inner-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.tagWrapper {
  text-align: center;
  width: 100%;

  &.colour {
    width: 65px;
  }

  .ant-tag {
    width: 100%;
  }
}
