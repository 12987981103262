.metricsContainer {
  max-width: 100%;
  max-height: 100%;

  .switchContainer {
    padding-bottom: 2rem;
  }

  .overallContainer {
    font-size: 2.5rem;
    margin-bottom: 2rem;

    .ant-col {
      background: white;
      border: $gray-6 solid 1px;
      border-radius: 8px;
      padding: 1.5rem 2rem;
      margin-right: 1rem;
    }
  }

  th,
  td {
    text-align: center;
  }

  .ant-table-tbody > tr.ant-table-row > td {
    background: $gray-1;
  }

  .ant-table-header,
  .ant-table-summary {
    td,
    th {
      background: $gray-3;
    }
  }

  .ant-table-expanded-row {
    td {
      overflow: auto;
    }
  }

  & > .ant-table-wrapper {
    max-height: 100%;
    border: solid gray 1px;
    border-radius: 3px;
    //overflow: auto;
  }

  .singleTable {
    th,
    td {
      font-size: 1.4rem;
      padding: 0.2rem 1rem;
    }
  }

  .subTable {
    margin-bottom: 1rem;

    .ant-table-container:after {
      box-shadow: none;
    }

    th,
    td {
      font-size: 1.4rem;
      padding: 0.2rem 1rem;
    }

    td {
      white-space: nowrap;
    }
  }
}

.adminDashboard {
  background: white;
  padding: 1rem 4rem 0;
  overflow: auto;
  width: 100%;
  flex-flow: column;
  display: flex;
  gap: 1rem;

  h1 {
    padding-top: 1rem;
  }

  .sideBySideContainer {
    max-width: 1500px;
    margin-right: auto;
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
    overflow-y: auto;

    & > * {
      padding-bottom: 2rem;
    }

    /* custom scrollbar */
    ::-webkit-scrollbar {
      width: 20px;
    }

    ::-webkit-scrollbar-track {
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #d6dee1;
      border-radius: 20px;
      border: 6px solid transparent;
      background-clip: content-box;
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: #a8bbbf;
    }

    h2 {
      font-size: 1.6rem;
      font-weight: $semibold;
    }

    .formContainer {
      flex-basis: 60%;
      padding: 1rem 2rem 3rem 0;
      overflow-y: auto;
    }

    .previewContainer {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      flex-basis: 40%;
      row-gap: 4rem;
      overflow-y: auto;

      .iconContainer {
        max-width: 4rem;
        //margin-bottom: -2rem;

        img {
          width: 100%;
        }
      }

      .refreshContainer {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 0;
        cursor: pointer;

        .iconContainer {
          margin-bottom: 0;
        }

        button {
          font-size: 1.4rem;
        }
      }

      .previewCardWrapper {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
      }

      .previewCard {
        width: 100%;
        flex-basis: 10rem;
        height: fit-content;
        background-color: $gray-4;
        padding: 0.75rem 1.5rem;
        border-radius: 0.5rem;
        font-size: 1.5rem;
        display: flex;
        flex-direction: row;
        column-gap: 1rem;
        align-items: center;
        color: black;

        .cardLogo {
          max-width: 4rem;

          img {
            width: 100%;
          }
        }

        .chartPreviewContainer {
          min-height: 15rem;
          height: fit-content;
          max-height: 25rem;
        }

        .imagePreviewContainer {
          max-height: 14rem;
          height: 95%;
          max-width: 100%;

          img {
            max-height: 100%;
            max-width: 100%;
            object-fit: scale-down;
          }
        }

        .cardContents {
          height: 100%;
          max-width: 100%;
        }

        h2 {
          margin-bottom: 0.5rem;
          margin-top: 0.5rem;
        }

        &.small {
          font-size: 1.3rem;
          flex-basis: 8rem;

          h2 {
            margin-bottom: 0;
          }
        }

        &.hover {
          cursor: pointer;
          border: 1px solid transparent;

          &:hover {
            border-color: black;
          }
        }

        &.orange {
          background-color: $orange-1;
        }

        &.purple {
          background-color: $purple-1;
        }

        &.pink {
          background-color: $magenta-1;
        }

        &.blue {
          background-color: $geekblue-1;
        }

        &.green {
          background-color: $green-1;
        }

        &.red {
          background-color: $red-2;
          border: 2px solid $red-4;
        }
      }
    }
  }

  .formContainer {
    .ant-form {
      padding: 0;

      .ant-form-item {
        margin-bottom: 1.5rem;

        &.primaryLabel {
          label {
            color: $primary;
          }
        }

        &.topLabel {
          label:not(.ant-radio-wrapper) {
            min-width: 9rem;
          }
        }

        label,
        input,
        span,
        textarea {
          font-size: 1.4rem;
        }
      }

      .solidRadioButtons {
        .ant-radio-button-wrapper {
          color: $blue;
          border-color: $blue;

          &:hover {
            background-color: $blue-1;
            color: $blue-5;
          }
        }

        .ant-radio-button-wrapper-checked {
          background: black;
          color: white;

          &:hover {
            background-color: $gray-10;
            color: $gray-5;
          }
        }
      }

      h2 {
        margin-bottom: 1rem;
        margin-top: 4rem;
      }

      .formSubmit {
        background: $purple-6;
        border-color: $purple-6;
        height: 3rem;
        padding: 0 2rem;
      }

      .buttonTitleFormContainer {
        display: flex;
        flex-direction: row;
        column-gap: 1rem;
        align-content: center;
        align-items: center;
        margin-bottom: 1.5rem;

        button {
          padding: 0 2rem;
          font-size: 1.4rem;
        }
      }

      .subTopicFormContainer,
      .rowFormContainer {
        display: flex;
        flex-direction: row;
        column-gap: 2rem;
      }

      .subTopicFormContainer .ant-form-item:first-child {
        flex-grow: 1;
      }

      .mockPaperQuestionFormContainer {
        display: inline-flex;
        flex-direction: column;
        row-gap: 1rem;
        border-radius: 0.5rem;
        border: 1px solid $gray-5;
        padding: 1rem;
        width: calc(95% - 7rem);
        margin-right: 2%;
        margin-left: 2%;

        .ant-form-item {
          margin-bottom: 0;
        }

        .subTopicFormContainer .topicSelector {
          max-width: max(calc(100% - 40rem), 30rem);
        }

        .mockPaperQuestionPreview {
          min-width: 3rem;
          span {
            font-size: 18px !important;
          }
        }

        h2 {
          margin-top: 0;
          margin-bottom: 0;
        }

        .mockPaperListField {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          :nth-child(1 of .mockPaperListField) {
            .listFormItem .ant-form-item-label label {
              color: #0058de !important;
            }
          }

          :nth-child(2 of .mockPaperListField) {
            .listFormItem .ant-form-item-label label {
              color: #b82fc9 !important;
            }
          }

          :nth-child(3 of .mockPaperListField) {
            .listFormItem .ant-form-item-label label {
              color: #f40f39 !important;
            }
          }

          :nth-child(4 of .mockPaperListField) {
            .listFormItem .ant-form-item-label label {
              color: #ef6e08 !important;
            }
          }
        }
      }

      .mockPaperQuestionWrapper {
        & > .listFormItem > * > .ant-form-item-label label {
          font-size: 2rem;
        }
      }

      .simpleListFormItem {
        display: inline-flex;
        width: calc(100% - 7rem);

        :first-child {
          flex-grow: 1;
        }
      }

      .submitButtonContainer {
        display: flex;
        flex-direction: row;
        column-gap: 2rem;
      }

      .listFormItem {
        .ant-form-item-label label {
          color: $primary !important;
        }
      }
    }
  }

  .formContainer.mock-paper {
    h2 {
      font-size: 2rem;
      font-weight: 600;
      margin-top: 3rem;
    }
  }
}

.mockPaperPreviewModal {
  .ant-modal-body {
    overflow-y: auto;
    max-height: 90vh;
    display: flex;
    flex-direction: column;

    & .ant-space {
      display: flex;
      flex-grow: 1;
    }
  }

  .mockPaperPreviewImage {
    display: block;
    max-width: 100%;
    max-height: 30rem;
    margin: 0 auto;
  }
}

.adminHeader {
  display: flex;
  flex-direction: row;
  column-gap: 3rem;
  align-items: center;
  padding-top: 1rem;

  h1 {
    margin-bottom: 0;
    padding-top: 0;
  }

  .totalBox,
  .errorsBox {
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
    font-weight: $semibold;
    font-size: 1.7rem;
  }

  .totalBox {
    background: $geekblue-1;

    .value {
      color: $primary;
    }
  }

  .errorsBox {
    background: $red-1;

    .value {
      color: $red-6;
    }
  }

  .devSwitch {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    column-gap: 1rem;
  }
}

.content-creator-container {
  display: flex;
  flex-direction: column;
  padding: 0 1rem 4rem;
  flex-wrap: wrap;

  h1,
  h2,
  h3,
  h4 {
    font-family: "Nunito Sans", sans-serif;
    font-weight: 600;
  }

  p {
    font-family: "Montserrat", sans-serif;
    font-size: 1.6rem;
    margin: 0 0 1rem 0;
  }

  .content-welcome {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;

    img {
      width: 65px;
      margin-right: 20px;
    }
  }

  .welcome--text {
    display: flex;
    flex-direction: column;

    h1 {
      font-size: 3rem;
      margin: 0;
    }

    p {
      font-size: 1.8rem;
    }
  }

  // MAIN COLUMNS --------------------------------------- //
  .content-col {
    display: flex;
    width: 100%;
    gap: 5rem;
    flex-grow: 1;
    flex-flow: row;
  }

  .content-col-left {
    background: white;
    width: 30%;
    min-width: 30rem;
    max-width: min(70rem, 100%);
    flex-grow: 1;
    flex-basis: 30%;
    display: flex;
    flex-direction: column;
    gap: 4rem;
  }

  .content-col-right {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    flex-grow: 1;
    flex-basis: 60%;
  }

  // STATS --------------------------------------- //
  .content-stats {
    background: #f0f8fc;
    padding: 2.5rem 3rem;
    border-radius: 0.8rem;

    h3 {
      font-size: 2rem;
    }

    p {
      font-size: 1.4rem;
    }
  }

  .creator--stat {
    display: flex;
    gap: 2rem;
    padding: 1rem 0.5rem;
    border-bottom: 1px solid #bbbbbb;

    p,
    h4 {
      margin: 0;
      font-size: 1.6rem;
      color: #0058de;
    }
  }

  .motivation--message {
    display: flex;
    align-items: flex-end;
    margin-top: 1rem;
    gap: 1rem;

    p {
      margin: 0;
      font-size: 1.5rem;
      font-weight: 500;
    }
  }

  // CREATE CONTENT --------------------------------------- //
  .create-content {
    display: flex;
    flex-direction: column;
    padding-bottom: 3rem;

    img {
      width: 65px;
      margin-right: 20px;
    }

    h3 {
      font-size: 2.2rem;
      margin-bottom: 0.1rem;
    }

    p {
      font-size: 1.4rem;
    }
  }

  .create--header {
    display: flex;
    margin-bottom: 1rem;
  }

  .content-new-manage {
    display: flex;
    padding: 1rem 0.5rem;
    border-bottom: 1px solid #a8a8a8;
    justify-content: space-between;
    align-items: center;

    p {
      margin: 0;
      font-family: "Nunito Sans", sans-serif;
      font-size: 1.7rem;
    }
  }

  .content--nav {
    display: flex;
    border: 1px solid #0058de;
    border-radius: 0.4rem;
    width: fit-content;
    max-height: 4rem;
    overflow: hidden;
  }

  .content--btn,
  .content--btn-2 {
    &,
    & > * {
      margin: 0;
      padding: 1rem 1.5rem;
      height: 100%;
      font-size: 1.3rem;
      font-weight: 500;
      transition: all ease-in-out 20ms;
      cursor: pointer;
      align-self: center;
    }

    &:hover {
      &,
      & > * {
        background-color: $blue;
        color: white;
      }
    }
  }

  .content--btn {
    color: white;
    background: #000;
  }

  .content--btn-2 {
    color: $blue;
  }

  // LINK CARDS --------------------------------------- //
  .content--link--card {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 60rem;
    padding: 12px 20px;
    border: 1px solid #cbcbcb;
    border-radius: 0.8rem;
    cursor: pointer;
    color: black !important;

    img,
    .anticon {
      width: 40px;
      margin-right: 20px;
      font-size: 40px;
    }

    &:hover {
      background: #000;

      &,
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: white !important;
      }
    }
  }

  .link--text {
    flex-grow: 1;

    h3 {
      font-size: 2rem;
      margin-bottom: 0;
    }

    p {
      font-size: 1.4rem;
      width: 100%;
      margin-bottom: 0;
    }
  }
}

.question-popover .ant-radio-button-wrapper {
  &:hover {
    background-color: $blue;

    a {
      color: white !important;
    }
  }
}

.adminTable {
  margin-top: 2rem;

  table {
    min-width: 100rem;
  }

  td {
    font-size: 1.2rem;

    .tableImage {
      max-height: 8rem;
      max-width: 14rem;
    }
  }

  th {
    font-size: 1.3rem;
    font-weight: bold !important;
    background: $gray-2 !important;
  }

  th,
  td {
    min-width: 10rem !important;
  }

  .ant-alert {
    .ant-alert-message {
      font-size: 1rem;
    }
  }

  .btnContainer {
    display: flex;
    column-gap: 0.5rem;
  }
}
