.sidebar {
  min-width: 10rem;
  max-width: 10rem;
  overflow-y: auto;

  @include media("<=905px") {
    min-width: 9rem;
    max-width: 9px;
  }
  @include media("<=450px") {
    min-width: 8rem;
    max-width: 8rem;
  }

  &.hidden {
    display: none;
  }
}

.sidebarDrawer {
  min-width: 15rem;
  max-width: 15rem;

  .ant-drawer-body {
    margin-top: 0.5rem !important;
  }

  .ant-drawer-mask {
    width: calc(100vw - 11rem);
  }

  @include media("<=905px") {
    min-width: 13rem;
    max-width: 13rem;
    .ant-drawer-mask {
      width: calc(100vw - 10rem);
    }
  }
  @include media("<=450px") {
    min-width: 10rem;
    max-width: 10rem;
    .sidebarDrawer .ant-drawer-mask {
      width: calc(100vw - 8rem);
    }
  }
}

.sidebar {
  background: $blue;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0.3rem 0 0.6rem rgba(107, 107, 107, 0.5);
  z-index: 999;
  font-family: $font;
  transition: width 0.1s ease-in-out;
  position: relative;
  height: 100vh;

  &.extended {
    box-shadow: inset -0.3rem 0 0.6rem rgba(107, 107, 107, 0.5);
  }
}

.sidebarDrawersWrapper {
  .ant-drawer {
    z-index: 998;
  }
}

.sidebarDrawer {
  .ant-drawer-mask {
    opacity: 0 !important;
    transition: none !important;
    animation: none !important;
  }

  .ant-drawer-close {
    right: 0;
    padding: 1.85rem 1rem 0 0;
    margin-right: 0;
    font-size: 1.2rem;

    .anticon {
      padding-left: 0.3rem;
    }
  }

  .ant-drawer-body {
    margin: 4rem 0 0 0;
    padding: 0;
  }
}

.sidebarDrawer.tourOpen .ant-drawer-body {
  overflow: hidden;
}

.logo {
  display: flex;
  justify-content: center;
  padding: 1em 0;

  .mainlogo {
    width: 100%;
    height: auto;
    max-width: 40px;

    @include media("<=905px") {
      max-width: 30px;
    }

    @include media("<=450px") {
      max-width: 25px;
    }
  }
}

.account-btns {
  margin-bottom: 1.5rem;

  ul {
    margin-bottom: 0;
  }
}

.feature-btns,
.account-btns {
  width: 100%;
  flex-wrap: nowrap;
  padding: 0;

  ul {
    margin-bottom: 0;
  }

  li {
    color: white;
    width: 100%;
    font-weight: 600;
    cursor: pointer;
    -webkit-transition: all 0.075s linear;
    -moz-transition: all 0.075s linear;
    -o-transition: all 0.075s linear;
    transition: all 0.075s linear;
    list-style: none;
    border-left: 0.2em solid transparent;
    border-right: 0.2em solid transparent;

    &.disabled {
      cursor: not-allowed;
      -webkit-transition: none;
      transition: none;
      color: #979797 !important;

      & a {
        cursor: inherit;
      }
    }

    &:hover:not(.disabled) {
      background: #0a44c2;
      -webkit-transition: ease-in-out 0.15s linear;
      transition: ease-in-out 0.15s linear;
    }

    &:hover:not(.disabled),
    &.current {
      border-left-color: white;
    }

    a {
      color: inherit;
      margin: 0;
      width: 100%;
      height: 100%;
      padding: 0.9rem 0;
      display: flex;
      flex-direction: column;
      row-gap: 0.3rem;
      align-items: center;

      svg {
        font-size: 2.5rem;
      }

      p {
        font-weight: 700;
        font-size: 1.2rem;
        margin: 0.3em 0 0 0;
        padding: 0;
        text-align: center;

        @include media("<=450px") {
          padding: 0 0.5rem;
        }
      }
    }
  }

  li.selected,
  li:active,
  .tagMenuItem {
    &:not(.disabled) {
      background: white;
      color: $blue;
    }

    &:hover:not(.disabled) {
      border-left-color: $blue;
      background: $gray-4;
    }
  }

  &.drawer li {
    background: white;
    color: $blue;

    &:hover:not(.disabled) {
      border-right-color: $blue;
      background: $gray-4;
    }
  }
}

.sidebar-divider {
  margin: 0 0 0.8rem;
  background: $gray-5;
  opacity: 0.4;
}

.classesDrawerContainer {
  display: flex;
  flex-direction: column;

  .custom_button,
  .tagWrapper {
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    display: block;
  }

  .tagWrapper {
    height: 4rem;
    font-weight: 700;

    .ant-tag {
      width: 100%;
      height: 100%;
      white-space: normal;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      text-align: center;
      overflow-y: hidden;
      overflow-x: visible;

      span {
        max-height: 100%;
        overflow: hidden;
        text-overflow: ellipsis;

        // Progressive enhancement for modern (webkit) browsers to add ellipsis
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }

  .custom_button {
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 0.78em;
    padding-top: 3px !important;
  }

  .tagMenuItem {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
