/* -------------------- Trial Sign Up --------------------------- */

.trialContainer {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
  margin-top: 1em;

  @include media(">=tablet", "<desktop") {
    width: 80%;
  }

  @include media("<tablet") {
    width: 90%;
    margin-bottom: 0.4em;
  }

  &.paymentContainer {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 95%;
    text-align: justify;
    max-height: calc(95vh - 7rem);

    .ant-alert {
      text-align: left;
    }

    .trialHeaderItems {
      margin-top: 0;
    }

    .paymentHeaderText {
      display: flex;
      flex-direction: row;
      gap: 1rem;

      &Image {
        width: 22rem !important;
        color: #020202;
      }
    }
  }
}

.trialHeader {
  color: white;
  width: 100%;
  background: $blue;

  @include media("<tablet") {
    height: fit-content;
    min-height: fit-content;
  }

  &.paymentHeader {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    .paymentContainer {
      margin-top: 0;
      padding: 1em 0;
    }
  }

  .trialHeaderItems {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 3rem;

    @include media("<tablet") {
      flex-direction: column;
    }

    .paymentHeader .trialHeaderText {
      align-items: flex-end;

      .paymentHeaderIcon {
        margin-bottom: 0.15em;
      }
    }

    .trialHeaderColumn {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &.paymentHeader {
        flex-direction: row;
        align-items: center;
      }

      img {
        width: 175px;
        @include media("<tablet") {
          margin: 0 auto 0.5em;
        }
      }

      button {
        &.termsofserv {
          background: $blue;
          border: 1px solid $gray-1;
          font-size: 1.5rem;
          padding: 0.3em 0;
          font-weight: 600;
          border-radius: 0.3rem;

          @include media("<=tablet") {
            width: 60%;
            margin: 0.3em auto;
          }

          &:hover {
            background-color: $gray-10;
            color: $gray-1;
            border-color: $gray-10;
          }
        }
      }
    }

    .trialSteps {
      .ant-steps-icon {
        color: $blue;
      }

      .ant-steps-item-active .ant-steps-item-icon {
        background: $lime-5;
      }

      .ant-steps-item-wait .ant-steps-item-icon .ant-steps-icon {
        color: $blue;
      }

      .ant-steps-item-title {
        color: white;
      }

      .ant-steps-item-title::after {
        color: $gray-1;
        background: $gray-1;
      }
    }

    .stepsColumn {
      width: 100%;
      margin-left: 2em;

      @include media("<tablet") {
        margin-left: 0;
      }
    }

    .logo-trial {
      max-width: 120px;
      margin-bottom: 0.5rem;
    }

    .trialSteps {
      width: 100%;
      margin-bottom: 1em;
    }
  }

  .trialHeaderText {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;

    @include media("<desktop") {
      flex-direction: column;
      align-items: end;
    }

    h3 {
      font-weight: $semibold;
      font-size: 2.4rem;
      color: white;

      button {
        &.trialBack {
          font-size: 2.4rem;
          margin-right: 1rem;
          padding-left: 0;
          background: $blue;

          &:hover {
            color: $gray-10;
          }
        }
      }
    }

    .paymentHeaderIcon {
      font-size: 24px;
      line-height: 1;
      padding-left: 0;
      margin-right: 0.8rem;
    }

    p {
      font-size: 1.7rem;
      margin-top: 1rem;

      @include media("<tablet") {
        font-size: 1.5rem;
        margin: 0.5em auto;
      }
    }
  }
}

.trialAlert {
  margin-bottom: 1.5rem;
}

.trialMocksInfo {
  text-align: left;
}

.trialFeaturesList {
  margin-top: -1rem;
  margin-left: 1.5rem;
  font-weight: 600;
  color: $gray-8;
}

.trialMocksButton {
  margin-bottom: 1rem;
}

.features {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.featureListContainer {
  height: min-content;
  width: 45%;
  margin-left: 10px;
  margin-right: 10px;
  border: 1px solid $gray-5;
  border-radius: 8px;
  align-items: center;
  display: flex;
  flex-direction: column;

  h3 {
    font-size: 16px;
    line-height: 24px;
    margin-top: 0.6rem;
    font-weight: $semibold;
  }

  .featureList {
    width: 100%;

    .ant-list-header,
    .ant-list-footer {
      align-items: center;
      display: flex;
      flex-direction: column;
    }
  }

  .planIcon {
    font-size: 40px;
  }

  .planCost {
    color: $gray-6;
  }

  .featureItem {
    width: 100%;
    font-size: 12px;
    line-height: 20px;
    padding: 0 16px;
    margin-bottom: 0;

    b {
      color: $cyan-7;
      font-weight: bold;
    }

    span {
      font-weight: normal;
      color: $gray-9;
    }
  }
}

.paymentCard {
  height: min-content;
  width: 80%;
  margin: 1em auto;
  border: 1px solid $gray-5;
  border-radius: 8px;
  align-items: center;
  display: flex;
  flex-direction: column;

  &.site {
    flex-direction: row;
    width: 95%;

    .paymentTOS {
      margin-top: 0.5em;
    }
  }

  .paymentCardHeader {
    padding: 1em;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1em;
    background: $green-2;
    justify-content: center;
    width: 100%;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    border: inherit;

    &.site {
      margin: 0;
      border-top-left-radius: inherit;
    }

    .paymentIcon {
      font-size: 66px;
    }
  }

  .paymentCardSubHeader {
    padding: 1em;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1em;
    background: $green-1;
    justify-content: center;
    width: 100%;
    border-bottom-left-radius: inherit;
    border: inherit;

    &.site {
      flex-direction: column;
      margin: 0;
    }
  }

  .paymentCardHeaderColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 0.5rem;
    margin-right: 0.5rem;

    &.site {
      margin: 0;
      width: 55%;
    }

    &.site-left {
      width: 45%;
      border: inherit;
      border-top-left-radius: inherit;
      border-bottom-left-radius: inherit;

      h3 {
        margin-bottom: 0;
        margin-top: 0;
      }

      .left {
        align-items: flex-start;
      }
    }
  }

  .paymentSwitch {
    width: 90px;
    margin-right: 1em;
    background: $purple-7;

    &.ant-switch-checked {
      background: $cyan-8;
    }
  }

  .paymentTOS {
    margin-top: 1em;
    background: inherit;
    color: $green-10;
    border-color: $green-10;
  }
}

.siteFormContainer {
  display: flex;
  flex-direction: column;
  gap: 3rem;

  .siteForm {
    border-radius: 1rem;
    background: $primary;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &,
    h3,
    p {
      color: white;
    }

    a {
      background: white;
      border-radius: 0.5rem;
      padding: 0.75rem 2.5rem;
    }

    .name {
      font-size: 2rem;
      margin-bottom: 1rem;
    }

    h3 {
      font-size: 2rem;
    }

    p {
      font-size: 1.6rem;
      text-align: center;
      margin: 0;
    }

    button {
      width: 100%;
      color: $primary;
      font-weight: 650;

      &:hover {
        background: black;
        color: white;
      }
    }

    .paymentForm {
      background: white;
      border-radius: 1rem;
      padding: 2rem 3rem;

      .checkoutForm {
        width: 100% !important;
      }

      button {
        color: white;
        margin-left: 0;
        margin-top: 1rem;
      }
    }
  }
}

.licenceBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 1rem;
  background: #f0f8fc;
  padding: 3rem;

  .licenceCost {
    display: flex;
    flex-direction: row;
    gap: 0.75em;
    align-items: baseline;

    h2 {
      font-weight: 900;
      font-size: 2rem;
      margin: 0;
    }

    p {
      font-style: italic;
      margin: 0;
    }

    span,
    p {
      &.discounted {
        text-decoration: line-through;
        color: #eb4d4d;
        font-weight: 500;

        p {
          margin-bottom: 0;
        }
      }
    }
  }

  span.early {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    column-gap: 0.5em;
    white-space: nowrap;
    font-weight: 700;
    font-size: 1.75rem;

    span:not(.error) {
      font-weight: 700;
      color: #479a77;
      font-size: 1.3em;
    }

    p {
      font-style: italic;
      margin: 0;
    }
  }

  .paymentTOS {
    padding: 0;
  }
}

.financeEmailForm {
  width: 100%;

  .ant-form-item {
    margin-bottom: 1em;
    margin-top: 0.5em;
  }
}

.formDesc {
  color: $gray-8;
  font-size: 11px;
  line-height: 20px;
}

.paymentLogout {
  width: 120px;
  color: white;
}

.checkoutForm {
  width: 80%;

  label {
    width: 130px;
  }

  .ant-form-item {
    margin-bottom: 4px;
  }

  .checkoutElement {
    font-size: 16px;
    color: #424770;
    background-color: transparent;
    border: none;
    display: block;
    font-family: sans-serif;
    margin: 0;
    padding: 0;
    width: 100%;
    line-height: 1.2em;
    height: 1.2em;
    -webkit-animation: native-autofill-out 1ms;

    &:focus {
      box-shadow: none;
    }
  }
}

.paymentModal {
  .ant-modal-close {
    height: 7rem;
  }

  .ant-modal-close-x {
    color: white;
  }

  .ant-modal-content {
    border-radius: 6px;
  }

  .ant-modal-body {
    padding: 0;

    & > div:not(.paymentHeader) {
      overflow-x: auto;
    }

    > .ant-row > .ant-col {
      border-left: 2px dashed $gray-6;
      @media screen and (max-width: 768px) {
        border-top: 2px dashed $gray-6;
        border-left: none;
      }
    }
  }
}

.stepsColumn {
  padding-bottom: 0.2em;
}

.createAccountForm,
.createAccountSwitch {
  margin: 2em auto;
  max-width: 700px;

  label {
    font-weight: 600;
  }
}

.createAccountSwitch {
  margin-top: 0;
  margin-bottom: 0;
}

.verifycodwrap {
  max-width: 700px;
  margin: 2em auto 0;

  @include media("<tablet") {
    font-size: 2.2rem;
    margin: 0.3em auto 0;
  }

  h1 {
    font-size: 2.8rem;
    margin-bottom: 1em;

    @include media("<600px") {
      font-size: 2.2rem;
      text-align: center;
    }
  }
}

.trialSteps {
  @include media("<tablet") {
    display: none;
  }
}
