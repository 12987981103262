.accountCardsContainer {
  width: 100%;
  text-align: center;
  overflow-y: auto;
  display: flex;
  column-gap: 2rem;
  row-gap: 1.8rem;
  flex-wrap: wrap;
}

.accountInfoContainer {
  padding: 0 4rem 2rem;
  width: 100%;
  text-align: center;
  overflow-y: auto;
  display: flex;
  column-gap: 2rem;

  .ant-tabs-nav {
    background: white;
    border-radius: 1rem;
    margin: 2.5rem 0 2rem;
    padding: 0 2rem 1rem;

    .ant-tabs-tab {
      padding-top: 1.6rem;
      padding-bottom: 0.8rem;
    }
  }

  .ant-tabs-content {
    overflow-y: auto;
    max-height: 98%;
  }

  .card-small {
    flex-shrink: 1;
    min-width: fit-content;
    flex-grow: 1;
    flex-basis: 50rem;
    margin-inline: auto;
  }

  .card-large {
    flex-grow: 2;
    flex-basis: 50%;
  }

  .card-small,
  .card-large {
    border-radius: 1rem;

    .ant-divider-horizontal {
      margin-top: 0;
      margin-bottom: 1rem;
    }
  }

  .ant-slider div {
    padding: 0;
  }

  div,
  button,
  input {
    font-size: 1.5rem;
  }

  .subUserForm {
    width: min(max(70%, 500px), 100%);
    margin-left: auto;
    margin-right: auto;
    padding-top: 2rem;
    padding-bottom: 2rem;

    .ant-form-item {
      margin-bottom: 0.5rem;
    }

    .emailInput {
      flex-grow: 1;
    }

    .formButtons button {
      margin: 0.5rem 0;
    }
  }

  .subscriptionStatusRow {
    justify-content: center;
    flex-direction: row;
    display: flex;
    column-gap: 1rem;
    margin-bottom: 0.5rem;
    padding-right: 3rem;
    padding-left: 3rem;

    p {
      margin-bottom: 0;
    }

    .ant-btn {
      white-space: normal;
      height: fit-content;
    }

    .infoLabel {
      display: block;
      text-align: right;
    }

    .infoValue {
      align-items: flex-start;
      display: flex;
      text-align: left;
      flex: 1 1;
    }
  }

  .accountCard .infoLabel {
    flex: 0 0 45%;
  }

  .subscriptionCard .infoLabel {
    flex: 0 0 37%;
  }

  .subscriptionActive,
  .subscriptionInactive,
  .subscriptionTrial {
    padding-left: 0.4rem;
    margin-bottom: 0;
  }

  .subscriptionActive {
    color: #28a745;
  }

  .subscriptionTrial {
    color: orange;
  }

  .subscriptionInactive {
    color: #dc3545;
  }

  h2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 22px;
    font-weight: 800;
  }

  .ant-card-body > h3 {
    margin-top: 0.5rem;
    font-size: 20px;
    font-weight: 600;
  }

  b {
    font-size: 1.7rem;
  }
}

.subUserForm .formButtons .ant-form-item-extra {
  max-width: 600px;
  margin-left: auto;
}

.subUserPromptModal {
  .ant-modal-body {
    overflow-y: auto;
    height: 80vh;
  }

  p {
    text-align: justify;
  }

  .subUserForm {
    margin-bottom: 2rem;
    margin-left: 5%;
    margin-right: 5%;

    .ant-form-item-extra {
      font-size: 1.5rem;
    }

    .ant-form-item-label {
      text-align: start;
    }
  }
}

.changePasswordContainer {
  width: 100%;

  .accountInfoContainer {
    flex-direction: column;
  }

  .changePasswordForm {
    max-width: min(600px, 100%);
    margin-left: auto;
    margin-right: auto;

    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-form-item-label {
      min-width: 21rem;

      label {
        height: 4.5rem;
      }
    }
  }

  .backButton {
    margin: 2rem;
  }
}

.preferencesFormWrapper {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;

  .ant-list {
    flex-grow: 1;
  }

  .defaultTagGridContainer {
    width: 100%;

    .defaultTagGrid {
      display: grid;

      &.three {
        grid-template-columns: auto auto auto;
      }

      &.two {
        grid-template-columns: auto auto;
      }

      .defaultTagCell {
        border: solid $gray-5 1px;
        line-height: 4rem;

        .ant-tag {
          margin: 0 1rem 1rem;
          padding: 0 1rem;

          &,
          span {
            font-size: 1.4rem !important;
          }
        }
      }
    }
  }
}

.preferencesFormReset {
  margin-block: 1rem;
}

.referrals-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
  place-content: center;
  margin-inline: auto;
  max-width: fit-content;

  img {
    max-width: 100%;
    width: 50rem;
  }

  p {
    font-size: 1.75rem;
    line-height: 1;
    margin-bottom: 0;
  }

  .referrals-code-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
  }

  button {
    width: fit-content;
    padding-inline: 2rem;
    padding-block: 1rem;
    height: fit-content;
    border-radius: 1rem;
  }

  .referrals-code {
    padding-block: 2rem;
    padding-inline: 3rem;
    border-radius: 1.5rem;
    border: 3px dashed $primary;
    font-family: "recoleta", "Montserrat", "Nunito Sans", sans-serif;
    font-weight: 800;
    font-size: 4rem;
    margin-bottom: 0.5rem;
  }
}
