@import "colors";

.starter-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background: white;
  font-family: $font;
}

.grid-container {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.main-starter-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .extension-button-container {
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    width: 100%;
    padding: 0 5px 1.5rem 30px;

    #btn-extension {
      margin-left: -5px;
      background: white;
      border-color: $blue;
      color: $blue;
      height: 4rem;
      width: 100%;
      font-size: 1.8rem;
      font-weight: 700;

      svg {
        margin-bottom: -3px;
        margin-right: 5px;
        font-size: 1.9rem;
      }
    }

    #btn-extension:hover {
      background: $blue;
      color: white;
    }
  }
}

.starter-right-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 1rem 0 0.5rem;
  max-width: 5rem;
  background: white;

  &.top {
    justify-content: space-between;
    padding-top: 2.2rem;
  }
}

/* SLIDER ------------------------------------------------------------------- */

.slider {
  text-align: center;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;

  .starter-slide {
    scroll-snap-align: end;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    transform-origin: center center;
    transform: scale(1);
    transition: transform 0.75s;
    position: relative;
    flex-wrap: nowrap;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.slider-toggle-container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 1rem;
  background: white;
  border-radius: 3px;
  z-index: 999;

  .sliderToggle {
    color: white;
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 0.1rem;

    b {
      width: 100%;
    }
  }
}

.plenary-btn {
  background-color: $primary;
  border-color: $primary;

  &:hover {
    background-color: black;
    border-color: black;
  }
}

.starter-btn {
  background-color: $primary;
  border-color: $primary;

  &:hover {
    background-color: black;
    border-color: black;
  }
}

/* HEADER -------------------------------------------------------------------- */

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 0 0 1.5rem;
  align-items: center;
  position: relative;
}

.switcher-date {
  align-self: flex-end;
  margin-bottom: 0.75rem;
  margin-top: 3.5rem;
}

.switcher-date--starter {
  position: absolute;
  z-index: 1;
}

.date {
  font-size: 2.9rem;
  font-weight: 700;
  text-align: center;
  padding-top: 1rem;
  margin-left: 1rem;
  margin-top: 2rem;
}

.header.fullscreen {
  min-height: 10rem;

  .switcher-date {
    margin-top: 0;
    height: 100%;
    padding-top: 2rem;
  }

  .date {
    padding-top: 0;
    margin-top: 0;
    margin-bottom: auto;
  }
}

.starter-header-buttons {
  .custom_button {
    & > .small-label {
      display: none;
    }

    & > *:not([role="img"]) {
      margin-left: 8px;
    }

    @media (max-width: 1800px) {
      & > *:not([role="img"]):not(.small-label) {
        display: none;
      }
    }

    @media (max-width: 1800px) and (min-width: 1200px) {
      & > .small-label {
        display: inline-block;
      }
    }
  }
}

.plenary-header-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;

  .ant-btn {
    padding: 0.75rem 3rem;
    height: 100%;
    border-radius: 0.5rem;
  }

  .ant-btn-default {
    border-color: $primary;
    color: $primary;

    &:hover {
      background: black;
      color: white;
    }
  }
}

.starter-plenary-switch {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0;
  z-index: 999;
  margin-left: 1.5rem;
  padding: 0 4px 4px;
  border-radius: 0;
  background: white;

  button {
    flex-grow: 1;
    min-width: fit-content;
    padding: 3px 10px;
    font-size: 1.3rem;
    font-weight: 700;
    background: #ffffff;
    color: black;
    transition: all 500ms cubic-bezier(0.075, 0.82, 0.165, 1);

    &:hover {
      background: black;
      color: white;
    }
  }

  button:nth-child(1) {
    border-left: 1px solid $primary;
    border-right: 1px solid $primary;
    border-bottom: 1px solid $primary;
  }
  button:nth-child(2) {
    border-right: 1px solid $primary;
    border-bottom: 1px solid $primary;
  }

  button.win--active {
    background: $primary;
    color: white;
    font-weight: 700;
  }
}

.title {
  flex-grow: 1;
  padding: 0;
  text-align: center;

  .hidden {
    max-height: 0;
  }

  input,
  .ant-typography {
    width: 100%;
    text-align: center;
    font-size: 4rem;
    background: none;
    border: 0;
    font-weight: 700;
    padding-left: 0.5em;
  }
}

input.learning-obj {
  font-size: 2.6rem;
  font-weight: 600;
  margin-top: -5px;
}

/* TIMER --------------------------------------------------------------------*/

.cntdwn-timer {
  margin: 0;
  font-size: 5rem;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #d3ad3a;
  background: #fff2bd;
  width: fit-content;
  flex-grow: 0;
  height: 100%;

  .timerText {
    font-size: inherit;
    margin-right: 0.2em;
    color: black;
    line-height: 1;
    margin-top: 0.75rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
}

.timer-main-controls {
  display: flex;
  background: inherit;
  align-items: center;
  justify-content: space-around;
  border-radius: 0.7rem 0 0 0.7rem;
  column-gap: 0.23em;
  padding: 0 0 0 0.23em;

  svg {
    width: 1em;
    height: auto;
    margin-right: 0.23em;
  }
}

.timer-sec-controls {
  display: flex;
  font-size: 0.5em;
  //color: $green-10;
  flex-direction: row;
  background: inherit;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
  padding: 0.15em 0.4em;
  justify-content: space-around;
  width: 100%;
}

.timer-main-controls,
.timer-sec-controls {
  svg {
    cursor: pointer;

    &.secondary {
      color: #d3ad3a;
    }

    &:hover {
      color: $primary;
    }
  }
}

.functions {
  padding: 0;
  display: flex;
  align-items: center;
  height: 100%;
  position: absolute;
  z-index: 1;
  right: 0;
}

.function-btns {
  display: inline-flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 6px;

  button {
    border-radius: 3px;
    border: none;
    box-shadow: none;
    margin-bottom: 18px;
    color: $primary;

    &:hover {
      color: black !important;
    }

    svg {
      width: 2.2rem;
      height: 2.2rem;
      color: inherit;
    }
  }

  button:nth-child(1) {
    margin-bottom: 15px;
  }
}

.starter-btn,
.plenary-btn {
  padding: 0 11px;
}

.plenary-btn,
.starter-btn {
  &.ant-tooltip-disabled-compatible-wrapper,
  &.ant-tooltip-disabled-compatible-wrapper:hover {
    background-color: transparent;
    border-color: transparent;
    padding: 0;

    a {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}

.topicsPopup {
  li.ant-cascader-menu-item {
    display: flex;
    justify-content: flex-end;

    .difficultySpan {
      width: 100%;
    }
  }

  .topicNewTag {
    color: #214d3c;
    font-family: "Nunito Sans", sans-serif;
    font-weight: 800;
    margin-left: 1rem;
    line-height: 1.2;
    border-radius: 0.33rem;
    padding-inline: 0.66rem;
  }

  .topicNewDot {
    margin-left: 0.75rem;
    margin-right: 0.25rem;
  }
}

.topicSelector {
  align-self: flex-start;
  width: 100%;

  .topicNewTag,
  .topicNewDot {
    display: none;
  }
}

.starterModalButtons {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.starterModalActionButtons {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  .actionButton {
    width: fit-content;
    margin-right: 1rem;
  }
}

.videoContainer {
  overflow: hidden;
  width: 100%;
  height: 60vh;

  iframe {
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
  }
}

.help-image-modal {
  .ant-modal-body {
    max-height: 90vh;
    overflow-y: auto;
  }

  .help-image {
    width: 100%;
    padding: 1rem;
  }
}

.mock-papers-advert-button {
  position: absolute;
  bottom: 8.1%;
  left: 9%;
  height: 11.5%;
  width: 32%;
  background: #5d9879;

  &-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &:is(a).ant-btn {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    line-height: 1.5 !important;
    display: grid;
    place-content: center;
  }

  & * {
    font-family: "recoleta", serif;
    color: white;
    font-size: 120%;
  }

  &:hover {
    background: #375947;
  }
}

.homeButton {
  z-index: 999;
  position: absolute;
  left: 1.5rem;
  transition: top 200ms ease 300ms;

  &.starter,
  &.plenary.fullscreen {
    top: 5.5rem;
  }

  &.plenary {
    top: 3.5rem;
  }
}

.changeTypeButton {
  margin-left: 6rem;
}

.custom-question-modal,
.random-context-modal {
  h1 {
    font-family: "recoleta", serif;
    margin-bottom: 0;
    font-size: 26pt;
    line-height: 1;
    flex-grow: 1;
  }
}

.custom-question-modal {
  $customModalPadding: 2.4rem;

  .ant-drawer-body {
    display: grid;
    grid-template-rows: auto 1fr;
    max-height: 100dvh;
    flex-direction: column;
    padding: 0;
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 2rem;
    margin-bottom: 2rem;
    padding: $customModalPadding $customModalPadding 0;

    h2.danger {
      color: #fa0000;
      font-weight: 600;
      margin-block: auto;
      font-size: 2rem;
      line-height: 0;
    }

    .ant-btn {
      border-radius: 0.5rem;
      padding: calc(0.25rem + 1.5px) 2rem 0.25rem;
      text-transform: uppercase;
      height: fit-content;
    }
  }

  &__content {
    gap: 3.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr auto;
    grid-template-areas:
      "question answer"
      "keyboard keyboard";
    min-height: 0;
    max-height: 100%;
  }

  &__question-area,
  &__answer-area {
    min-height: 0;
    overflow: hidden;

    & > div {
      width: 100%;
      height: 100%;
    }
  }

  &__question-area {
    grid-area: question;
    padding-left: $customModalPadding;
  }

  &__answer-area {
    grid-area: answer;
    padding-right: $customModalPadding;
  }

  &__keyboard {
    position: relative;
    grid-area: keyboard;
    $keyboardBottomPadding: 1.5rem;
    height: calc(246px + $keyboardBottomPadding);
    padding-bottom: $keyboardBottomPadding;
    color: #2b577c;
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    background: #f4faff;
    --keyboard-background: #f4faff;
    border-top: 2px solid #dfecff;

    --keycap-height: 42px !important;

    // Toolbar switcher and accents
    --keyboard-accent-color: #0058de;
    --keyboard-toolbar-text: #0058de;
    //--keyboard-toolbar-text-active: red;
    --keyboard-toolbar-background-hover: #f5f5f7;

    // Key backgrounds
    --keycap-background: #c8e5fe;
    --keycap-background-hover: #f5f5f7;
    --keycap-background-active: #2d5a81;
    --keycap-background-pressed: #2d5a81;
    --keycap-secondary-background: #2d5a81;
    --_keycap-secondary-background-hover: #7d8795;
    --keycap-secondary-text: #f4faff;

    // Keyboard text
    --keycap-text: #2d5a81;
    --keycap-text-active: #f4faff;
    //--keycap-text-hover: #f4faff; // FIXME not working -- bug in library https://github.com/arnog/mathlive/issues/2526
    --keycap-text-pressed: #f4faff;
    //--keycap-shift-text: red;
    //--keycap-shift-color: red;
    --box-placeholder-color: #2d5a81;

    & > div {
      position: absolute;
      width: 100%;
      height: calc(100% - $keyboardBottomPadding);
      max-width: 100%;
      padding-inline: $customModalPadding;
      background: inherit;
      overflow: hidden;

      p {
        font-weight: inherit;
        font-size: inherit;
      }

      &:not(.custom-question-modal__keyboard__mathlive) {
        padding-top: $keyboardBottomPadding;
      }
      &.custom-question-modal__keyboard__none {
        padding-top: calc($keyboardBottomPadding + 1rem);
        padding-bottom: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p {
          font-size: 18px;
          margin-bottom: 0;
          font-weight: 500;

          strong {
            font-weight: 700;
          }
        }

        .info-text {
          background: #c1ffca6e;
          border: 2px solid #67bd74;
          border-radius: 0.5rem;
          padding: 4px 8px;
        }
        .info-math {
          background: #ffe4e4;
          border: 2px solid #e94e4e;
          border-radius: 0.5rem;
          padding: 4px 8px;
        }
        .info-tab {
          background: #dddddd6e;
          border: 2px solid #3e3e3e;
          color: #3e3e3e;
          border-radius: 0.5rem;
          padding: 2px 8px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;
          font-size: 0.7em;
          font-weight: 700;
        }
        .animation {
          min-height: 0;
          object-fit: scale-down;
          border: 1px solid #2b577c;
          border-radius: 0.5rem;
          width: fit-content;
          margin-block: 1.5rem;
        }
      }

      &.custom-question-modal__keyboard__mathlive {
        max-width: 0;
        padding: 0;

        &:has(.ML__keyboard.is-visible) {
          max-width: 100%;
        }
      }
    }
  }

  &__card {
    display: grid;
    grid-template-rows: auto 1fr;
    padding: 1rem 1.5rem;
    background: #fff;
    border: 1px solid #cbcbcb;

    &--preview {
      padding: 0;
      border: none;
    }

    h1 {
      font-family: "Montserrat", "Nunito Sans", sans-serif;
      font-size: 1.7rem;
      text-transform: uppercase;
      font-weight: 600;
      margin-block: auto;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      padding: 0 0 5px 0;
      border-bottom: 1px solid #ced5e1d1;
      margin-bottom: 1.5rem;
    }

    &__question {
      overflow-x: hidden;
      overflow-y: auto;
      font-size: 20px;

      $block-gap: 1rem;
      $row-gap: 0.5rem;
      $row-height: 40px;
      $border-radius: 0.5rem;

      &__wrapper {
      }

      .text-field:has(+ math-field),
      math-field:has(+ .text-field) {
        margin-right: $block-gap;
      }

      .text-field {
        min-width: 5rem;
        min-height: $row-height;
        cursor: pointer;
        background: #c1ffca6e;
        border: 2px solid #67bd74;
        border-radius: $border-radius;
        padding: 4px;
        display: inline;
        line-height: 1.4;
        margin-block: $row-gap;
        vertical-align: middle;
        white-space: pre-wrap;

        &:empty {
          display: inline-block;
        }

        &:not(:empty) {
          line-height: calc(1em + 16px + 2 * $row-gap);
        }

        &:focus-within {
          background: transparent;
          outline: none;
          cursor: text;
        }
      }

      .new-line-field {
        display: inline-grid;
        place-content: center;
        padding-inline: 0.5rem;
        background: $gray-4;
        border: 2px solid $gray-8;
        color: $gray-8;
        border-radius: $border-radius;
        min-height: $row-height;
        cursor: pointer;
        margin-block: $row-gap;
        vertical-align: middle;

        &__wrapper {
          .ant-badge {
            line-height: 1.5715 !important;
          }
        }
      }

      math-field {
        min-width: 5rem;
        min-height: $row-height;
        cursor: pointer;
        background: #ffe4e4;
        border: 2px solid #e94e4e;
        border-radius: $border-radius;
        vertical-align: middle;
        margin-block: $row-gap;

        .ML__base {
          cursor: inherit !important;
        }

        &:focus-within {
          background: transparent;
          outline: 1px auto #e94e4e;
          cursor: text;
        }

        &::part(menu-toggle),
        &::part(virtual-keyboard-toggle) {
          display: none;
        }
      }

      .insert-control {
        margin-inline: 0.25rem;
        margin-block: $row-gap;
        display: inline-grid;
        place-content: center;
        place-items: center;
        vertical-align: middle;
        cursor: pointer;
        padding: 4px;
        min-height: $row-height;
        font-size: 2.1rem;
        position: relative;

        &.Text {
          color: #67bd74;

          & > * {
            background: #c1ffca6e;
          }

          &:hover {
            & > * {
              background: #67bd74;
            }
          }
        }

        &.Equation {
          color: #e94e4e;

          & > * {
            background: #ffe4e4;
          }

          &:hover {
            & > * {
              background: #e94e4e;
            }
          }
        }

        &.NewLine {
          color: $gray-8;

          & > * {
            background: $gray-4;
          }

          &:hover {
            & > * {
              background: $gray-8;
            }
          }
        }

        & > * {
          margin-block: auto;
          border-radius: 50%;
          width: fit-content;
          scale: 0.33;
          transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        }

        &::before {
          content: none;
          position: absolute;
          z-index: 1;
          color: white;
        }
        &:hover {
          &::before {
            content: "+";
          }
          & > * {
            scale: 1;
          }
        }
      }
    }
  }

  .suggestion_keyboard {
    display: flex;
    flex-direction: column;

    &__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .ant-btn {
        border-radius: 0.5rem;
        padding: calc(0.25rem + 1.5px) 2rem 0.25rem;
        text-transform: uppercase;
        height: fit-content;
        color: inherit;
      }
    }

    &__content {
      display: grid;
      grid-template-columns: 1fr 1fr 1.2fr;
      min-height: 0;
      gap: 3.5rem;
    }

    &__section {
      overflow: hidden;
      display: flex;
      flex-direction: column;

      &__title {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: flex-start;
        align-items: baseline;
        gap: 1rem;
        margin-bottom: 1rem;

        > * {
          color: inherit;
          flex-grow: 0;
          margin-bottom: 0.5rem;
        }
        h1 {
          font-size: 2.2rem;
        }
        h2 {
          font-weight: 600;
          font-size: 1.5rem;
        }
      }

      &__content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1rem;
        overflow-x: clip;
        overflow-y: auto;

        .ant-btn {
          border-radius: 0.5rem;
          border: 2px solid #67bd74;
          color: black;
          transition: background-color 150ms linear;

          &:hover {
            background: #67bd74;
            transition: background-color 150ms linear;
            color: black;
          }
        }

        /* Firefox Custom Scrollbar */
        & {
          scrollbar-width: thin;
          scrollbar-color: #2b577c #bfd0de;
          /* Chrome, Edge, and Safari Custom Scrollbar */
          &::-webkit-scrollbar {
            height: 4px;
            width: 4px;

            &-track {
              background: #bfd0de;
            }

            &-thumb {
              background-color: #2b577c;
              border-radius: 6px;
            }
          }
        }
      }
    }
  }
}

.random-context-modal {
  .ant-btn {
    border-radius: 0.5rem;
    height: fit-content;

    &.cancel {
      color: #767676;
    }
  }

  &__switcher.ant-btn {
    border-radius: 0.5rem;
    border: 1px solid #94b4ff;
    background: #f3f7fe;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: baseline;
    color: #0058de;
    padding-block: 0.5rem;
    height: fit-content;

    & > * {
      margin: 0 !important;
      margin-block: auto !important;
    }

    &:hover {
      color: $blue-9;
    }

    .anticon {
      line-height: 0;
    }
  }
}

:root {
  --keyboard-zindex: 2001;
}

.MLK__keycap,
.MLK__row .shift,
.MLK__row .action {
  cursor: pointer !important;
}
.MLK__toolbar .action.disabled {
  cursor: default !important;
}
.MLK__plate {
  font-family: "Segoe UI", "Roboto", "Nunito Sans", "Helvetica Neue", sans-serif !important;
}
