.appMultiplicationWrapper {
  height: 92vh;
  width: 100%;
  padding-top: 4rem;

  &.fullscreen {
    padding: 1rem;
    height: 100vh;
  }
}

.multiplicationContainer {
  width: fit-content;
  height: 100%;
  padding: 1rem;
  margin: 0 auto;
  justify-content: center;
  column-gap: 2rem;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: min-content fit-content(60rem);
  grid-template-rows: min-content 1fr;

  &.fullscreen {
    max-height: 100%;
    grid-template-columns: 1fr;
  }

  .multiplicationHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 2rem;
    gap: 1em;

    &Right {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;
      flex-wrap: wrap;
      gap: 0.8em;
      flex-basis: 0;
      flex-grow: 1;
    }

    .cntdwn-timer {
      justify-self: flex-start;
      font-size: 4rem;

      border-color: #f2b61a;
      background: #ffe55c1c;
    }

    &.fullscreen {
      .cntdwn-timer {
        font-size: 5rem;
      }
    }

    .mulBtn {
      &.answers {
        min-width: 15rem; // Prevents layout shift
      }

      &.fullscreen {
        color: $blue;
        border-color: $blue-7;
      }

      &.reload {
        svg {
          font-size: 2.4rem;
          margin-left: -0.5em;
        }
      }
    }
  }

  .gridContainer {
    display: grid;
    grid-template-rows: fit-content(0) minmax(0, 1fr);
    grid-template-columns: minmax(0, 1fr);
    height: 100%;
    width: fit-content;

    &.fullscreen {
      width: 100%;
    }
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    row-gap: 8px;
    column-gap: 10px;

    max-height: 100%;
    max-width: 100%;
    min-height: 0;
    height: fit-content;
    width: 100%;
    margin: 0 auto;

    background: white;
    padding: min(3vw, 4rem);
    border: #1660e7 solid 1px;
    border-radius: 16px;
    font-size: 2.5rem;

    @media (min-aspect-ratio: 9 / 7) and (max-height: 900px) {
      height: 100%;
      width: fit-content;
    }

    &.fullscreen {
      font-size: 5vh;
      height: 100%;
      width: fit-content;
      max-height: 1200px;

      @media (max-aspect-ratio: 7 / 9) {
        height: fit-content;
      }
    }

    mjx-container[jax="SVG"] > svg {
      max-width: 100% !important;
    }

    .gridCell {
      $opacity: 0.5;

      max-width: 100%;
      max-height: 100%;
      border: solid 1px $gray-border;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $gray-1;
      border-radius: 8px;
      font-size: 1em;
      padding: 0 3px;
      aspect-ratio: 1/1;
      overflow: hidden;

      &.algebraic {
        font-size: 0.9em;
      }

      &:not(.headerCell):not(.sideCell) {
        border-color: $gray-5 !important;

        &.hideAnswers {
          color: transparent !important;
          background-color: white !important;
          border-color: $gray-border !important;
        }
      }

      &.headerCell,
      &.sideCell {
        background-color: #f2fafd;
        border-color: #1660e7;
        font-weight: bold;
      }

      &.headerCell.sideCell {
        background-color: $green-background;
        border-color: $green-border;
        font-size: 0.8em;
      }

      &.red {
        background: $red-background;
        border-color: $red-border;

        &:not(.headerCell):not(.sideCell) {
          background: transparentize($red-background, 1 - $opacity);
        }

        &:hover {
          border-color: $red-border-active;
        }
      }

      &.orange {
        background: $orange-background;
        border-color: $orange-border;

        &:not(.headerCell):not(.sideCell) {
          background: transparentize($orange-background, 1 - $opacity);
        }

        &:hover {
          border-color: $orange-border-active;
        }
      }

      &.yellow {
        background: $yellow-background;
        border-color: $yellow-border;

        &:not(.headerCell):not(.sideCell) {
          background: transparentize($yellow-background, 1 - $opacity);
        }

        &:hover {
          border-color: $yellow-border-active;
        }
      }

      &.green {
        background: $green-background;
        border-color: $green-border;

        &:not(.headerCell):not(.sideCell) {
          background: transparentize($green-background, 1 - $opacity);
        }

        &:hover {
          border-color: $green-border-active;
        }
      }

      &.cyan {
        background: $cyan-background;
        border-color: $cyan-border;

        &:not(.headerCell):not(.sideCell) {
          background: transparentize($cyan-background, 1 - $opacity);
        }

        &:hover {
          border-color: $cyan-border-active;
        }
      }

      &.blue {
        background: $blue-background;
        border-color: $blue-border;

        &:not(.headerCell):not(.sideCell) {
          background: transparentize($blue-background, 1 - $opacity);
        }

        &:hover {
          border-color: $blue-border-active;
        }
      }

      &.purple {
        background: $purple-background;
        border-color: $purple-border;

        &:not(.headerCell):not(.sideCell) {
          background: transparentize($purple-background, 1 - $opacity);
        }

        &:hover {
          border-color: $purple-border-active;
        }
      }

      &.pink {
        background: $pink-background;
        border-color: $pink-border;

        &:not(.headerCell):not(.sideCell) {
          background: transparentize($pink-background, 1 - $opacity);
        }

        &:hover {
          border-color: $pink-border-active;
        }
      }
    }
  }

  .ant-col {
    display: flex;
    justify-content: center;

    align-content: center;
    text-align: center;
    align-items: center;

    &.left {
      justify-content: flex-start;
    }

    &.right {
      justify-content: flex-end;
    }
  }
}

.sidePanel {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 2rem;
  max-width: 340px;
  flex: 1 1 340px;
  min-height: 0;
  min-width: fit-content;

  .customSwitch,
  .radioButtonsContainer {
    & > span {
      display: flex;
      color: #1d1d1d;
      font-weight: 700;
      text-align: left;
      justify-self: flex-start;
    }
  }

  .sideTitle {
    margin-bottom: 1em;
  }

  .ant-btn[disabled]:not(.ant-btn-text) {
    color: #50505088;
    border-color: #505050;
  }

  .ant-btn-text:not([disabled]) {
    color: $primary;
  }

  &Content {
    width: 100%;
    height: 100%;
    padding: 0 1rem;
    overflow-y: auto;
    overflow-x: visible;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }

  .sideBox {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem 3rem;
    width: 100%;

    gap: 0.75rem;

    background: white;
    border: #1660e7 solid 1px;
    border-radius: 8px;

    p,
    h3 {
      margin-bottom: 0;
    }

    h3 {
      font-weight: 900;
      line-height: 1.8rem;
      font-size: 1.6rem;
    }

    p {
      width: fit-content;
      font-weight: 650;
    }

    button {
      margin-bottom: 0 !important;

      &.ant-btn-primary {
        margin-top: 1rem;
      }
    }

    .success {
      color: #09ad19;
    }

    .error {
      color: #d60909;
    }

    .narrowControl {
      max-width: 18rem;
      width: 100%;
    }

    .checkboxes {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .ant-checkbox-group-item {
        margin: 0;
        font-size: 1.5rem;
        width: 5rem;
      }
    }

    .radioButtonsContainer {
      padding-bottom: 1rem;

      & > span {
        margin-bottom: 0.5rem;
      }

      .ant-radio-group {
        display: flex;
        justify-content: flex-start;
        flex-wrap: nowrap;
      }
    }

    .colourSelectorContainer {
      margin-top: 1rem;
    }
  }
}

.sidePanel,
.multiplicationPrintModal {
  .sideTitle {
    font-size: 2.2rem;
    font-weight: 900;
  }

  p {
    font-size: 1.4rem;
    text-align: justify;

    &.hint {
      padding-top: 1rem;
      color: $gray-7;
    }
  }

  .ant-btn:not(.modalClose):not(.ant-switch) {
    width: 100%;
    margin-bottom: 1rem;
    font-size: 1.4rem;
  }

  .ant-tooltip-disabled-compatible-wrapper {
    width: 100%;
  }
}

.multiplicationPrintModal {
  .ant-modal-body {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}

.customSwitch {
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  .ant-switch {
    width: 4rem !important;
  }

  &.disabled {
    span {
      color: $gray-6 !important;
    }
  }

  .ant-switch {
    background: transparent;

    &,
    &:hover {
      box-shadow: 0 0 0 2px #cdedff;
    }
  }

  &.singleLabel .ant-switch:not(.ant-switch-checked) {
    &,
    &:hover {
      box-shadow: 0 0 0 2px #0000001a;
    }

    .ant-switch-handle::before {
      background: $gray-5;
    }
  }

  &:not(.singleLabel) .ant-switch,
  &.singleLabel .ant-switch-checked {
    .ant-switch-handle::before {
      background: $primary;
    }
  }

  .ant-click-animating-node {
    box-shadow: unset;
  }

  &:not(.singleLabel) span {
    color: #707070;
    font-size: 1.4rem;
  }

  .selected {
    color: $primary !important;
  }

  .ant-switch {
    width: 5rem;
  }

  &.singleLabel {
    flex-direction: row;
    justify-content: space-between;
  }
}
