.custom_button {
  border-color: #d9d9d9 !important;
  font-size: 0.9em;
  line-height: 30px;
  height: fit-content;
  min-width: 5rem;

  &:is(a).ant-btn-sm {
    line-height: 30px;
  }

  &:not(.square) {
    border-radius: 2rem;
  }

  span {
    text-transform: uppercase;
  }

  & {
    &:hover:not(.disabled) {
      color: white;
      background: $primary;
    }
  }

  &.secondary {
    border-color: $primary !important;
    color: $primary;
  }

  &.primary,
  &.current {
    color: white;
    background: $primary;
  }

  &.primary:hover:not(.disabled) {
    background: black;
  }

  &.disabled {
    background: $gray-3;
    color: rgba(0, 0, 0, 0.25);
  }
}
