.mock-papers-wrapper {
  padding: 1rem 2rem;
  min-height: 100vh;
  width: 100%;
}

.mock-papers-selector {
  display: grid;
  grid-template-columns: fit-content(30rem) 1fr;
  gap: 2rem;
  height: 100%;
  width: 100%;

  padding: 1.5rem 1rem;

  &__side-panel {
    display: flex;
    flex-direction: column;
    gap: 3rem;

    h1 {
      font-family: "recoleta", serif;
      margin-bottom: 0;
      font-size: 32pt;
      line-height: 1;
    }
  }

  &__links-section {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;

    &,
    h2 {
      font-size: 1.5rem;
    }

    h2 {
      margin: 0;
      color: $gray-7;
      text-transform: uppercase;
    }

    .custom_button {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }

    &__coming-soon {
      position: relative;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      &__overlay {
        position: absolute;
        inset: 0;
        z-index: 1;
        background: radial-gradient(rgba($primary, 0.9), rgba($primary, 0.05));
        color: white;
        font-size: 2rem;
        font-weight: 600;
        font-family: "recoleta", serif;

        display: grid;
        place-content: center;
        border-radius: 0.5rem;
      }
    }
  }

  &__copyright-notice {
    margin-top: auto;

    h2 {
      font-weight: 800;
      font-size: 1em;
    }

    p {
      font-size: 0.85em;
      margin-bottom: 1rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__side-panel,
  .our-library__main_panel,
  .our-library__content {
    min-height: 0;
    max-height: 100%;
    overflow: hidden;
  }

  .our-library__content {
    overflow-y: auto;
  }

  &__grid {
    h2 {
      margin-bottom: 0;
      text-transform: uppercase;
      font-size: 2.1rem;
      line-height: 1;
    }

    h3 {
      margin-top: 2rem;
      margin-bottom: 0.5rem;
      color: $gray-7;
      text-transform: uppercase;
      font-size: 1.5rem;
    }

    &-section {
      &:not(:last-of-type) {
        margin-bottom: 4rem;
      }
    }

    .save-item-fn-row {
      justify-content: flex-start;
      .saveItemTag {
        width: 100%;
        max-width: min(10rem, 100%);
        min-width: fit-content !important;
      }
    }

    .draggable-save.booster-style {
      border: solid 3px transparent;
      /* Uses white-to-white gradient padding-box on top of border-box background to create gradient border */
      background:
        linear-gradient(white, white) padding-box,
        linear-gradient(to right, #ff1b6b, #6cc7ee) border-box;

      &:hover {
        border-width: 2px;
        border-color: #6cc7ee;
      }
    }
  }
}

.mock_papers_modal {
  h1 {
    font-family: "recoleta", serif;
    font-size: 35pt;
    line-height: 1;
    margin-bottom: -0.1rem;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1rem 0;
    width: 100%;

    > .actionButton {
      max-width: fit-content;
      padding-left: 3rem;
      padding-right: 3rem;
      border-radius: 0.5rem;
    }
  }

  &__questions-box {
    max-height: 55vh;
    overflow-y: auto;
  }
}

.question_num_and_subtopic_list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &__label {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
  }
}

// Interactive tabbed view
.mock-paper {
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  position: relative;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 2.5rem;

    padding-bottom: 1rem;
    border-bottom: 1px solid $gray-5;

    h1,
    h2 {
      font-family: "recoleta", serif;
      line-height: 1;
      margin-bottom: 0;
      font-weight: 700;
      white-space: nowrap;
    }

    h1 {
      font-size: 33pt;
    }

    h2 {
      font-size: 26pt;
    }
  }

  &__question-number {
    &,
    &__number,
    &__buttons {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
    }
    & {
      gap: 0.75rem;
    }
    &__number {
      gap: 1rem;
      min-width: 16rem;
      cursor: pointer;
    }
    &__buttons {
      gap: 0.5rem;

      button {
        line-height: 1.5em;
      }
    }

    &__count {
      white-space: nowrap;

      color: $gray-7;
      font-size: 1.5rem;
      text-transform: uppercase;
    }

    &__select {
      &-content {
        display: flex;
        flex-direction: row;
        max-width: min(58rem, 90vw);
        gap: 0.5rem;
        flex-wrap: wrap;
        justify-content: flex-start;

        & > * {
          min-width: 6.8rem;
        }
      }
    }
  }

  &__tabs {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-end;
    column-gap: 1rem;
    row-gap: 0.5rem;
    flex-wrap: wrap;
    flex-shrink: 1;
    min-width: 0;

    & > * {
      max-width: 35%;

      & > button {
        max-width: 100%;
      }

      &:is(button),
      & button {
        padding-left: 2rem;
        padding-right: 2rem;

        & > * {
          display: block;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  &__fullscreen_btn {
    color: $primary;

    &__wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      gap: 1rem;
    }
  }

  &__content {
    max-height: 100%;
    overflow-y: auto;
    position: relative;
    $scrollbar-width: 17px;
    $top-padding: 1.5rem;

    &--hidden {
      display: none;
    }

    .paper_interactive {
      height: 5000px;

      &--hidden {
        display: none;
      }

      &__info_panel {
        position: absolute;
        right: 0;
        margin: $top-padding 1.5rem 0;
        z-index: 10;
      }

      &__overlay {
        position: fixed;
        $drawer-padding: 24px;
        right: calc(#{$drawer-padding} + #{$scrollbar-width});
        bottom: #{$drawer-padding};

        margin: 0 1.5rem;

        .sketchModal-footer {
          margin-right: 0;
          background: white;
          max-width: 100%;
        }

        &--hidden {
          display: none;
        }
      }

      &__content {
        margin-top: $top-padding;
        position: absolute;
        width: 100%;

        img {
          display: block;
          object-fit: scale-down;
          max-width: 60%;

          &:not(:last-of-type) {
            margin-bottom: 1rem;
          }
        }
      }

      &__canvas {
        touch-action: none;
        cursor: crosshair;

        &,
        & > div,
        .konvajs-content,
        .konvajs-content {
          height: 100%;
          width: 100%;
        }

        .konvajs-content {
          overflow: hidden;
          width: 100% !important;
          height: 100% !important;
        }
      }
    }
  }

  &__loading-overlay {
    position: absolute;
    inset: 0;
    display: grid;
    place-content: center;
    background: #ffffff55;
    z-index: 2;
  }
}

// Tools view
.mock-paper-tools {
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 2.5rem;
    margin-bottom: 0.5rem;
    padding: 1rem;

    h1 {
      font-family: "recoleta", serif;
      line-height: 1;
      margin-bottom: 0;
      font-weight: 700;
      white-space: nowrap;
      font-size: 33pt;
    }

    .custom_button {
      padding-left: 1.75rem;
      padding-right: 1.75rem;
    }
  }

  &__reminder {
    h3 {
      font-weight: 700;
      font-size: 2rem;
      margin-bottom: 0.5rem;
    }
    ul {
      margin-left: 2rem;
      font-family: "Montserrat", "Nunito Sans", sans-serif;
      margin-bottom: 0;

      li {
        padding-bottom: 0.25rem;
      }
    }
  }

  &__countdown {
    .tick {
      font-size: min(7rem, 6vw);
      display: flex;

      &__wrapper {
        display: flex;
        flex-direction: row;
        gap: 1rem;
      }

      &__controls {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        font-size: 2rem;
        gap: 1rem;

        & > * {
          cursor: pointer;
        }
      }

      &-text {
        font-size: 1.75rem;
        margin-inline: auto;
        display: block !important;
        width: fit-content;
        margin-bottom: 1.25rem;
      }
    }

    & [data-layout~="horizontal"] {
      display: flex;
      flex-direction: row;
      gap: 2rem;

      & > div {
        display: flex;
        flex-direction: column-reverse;
        position: relative;

        &:not(:last-child)::after {
          content: ":";
          position: absolute;
          right: calc(-1 * min(1.75rem, 1.7vw));
        }
      }
    }
  }

  &__hints {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
    counter-reset: hints;

    .mock-paper-tools__hint_column {
      padding: 1rem;

      border: 1px solid #cbcbcb;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      &::before {
        counter-increment: hints;
        content: "Hints " counter(hints);
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1.8rem;
        border-bottom: 1px solid #cbcbcb;
        margin-bottom: 1rem;
      }
    }
  }

  &__hint {
    flex-grow: 1;
    max-height: 2.5rem;
    // Close animation
    transition:
      max-height 300ms 0ms ease-out,
      margin-bottom 200ms 50ms ease-out;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;

    &:not(:last-of-type) {
      border-bottom: 1px solid #cbcbcb;
    }

    &[aria-expanded="true"] {
      max-height: 50%;
      margin-bottom: 0;
      transition:
        max-height 500ms ease,
        margin-bottom 300ms ease;
    }

    &_column {
      min-height: 0;
    }

    &__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 0.5rem;
      font-weight: 600;
      font-family: "Montserrat", "Nunito Sans", sans-serif;
      cursor: pointer;

      &:hover {
        color: $primary;
      }
    }

    &__content {
      min-height: 0;
      flex-grow: 1;
      flex-shrink: 1;
      display: grid;
      align-content: start;
      justify-content: center;

      img {
        min-height: 0;
        min-width: 0;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}

.mock-paper-drawer:has(.mock-paper-tools) .ant-drawer-body {
  padding: 0.5rem;
}

.question_marks_panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;

  padding: 1.5rem;
  border-radius: 0.5rem;

  background: #f0fefe;
  border: 1px solid #7fdae3;

  h3 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.4rem;
    margin-bottom: 0;

    &:not(:first-of-type) {
      margin-top: 2rem;
    }
  }

  &__marks {
    font-family: "recoleta", serif;
    font-size: 26pt;
    line-height: 1;
    margin-bottom: 0;
    font-weight: 700;
  }

  &__tags {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .ant-tag {
      border-radius: 0.5rem;
      padding: 0.5rem 1rem;
      margin: 0;
      text-align: center;
      color: black;
      font-weight: 500;
      font-size: 1.5rem;
    }
  }
}

.download_link {
  &:hover {
    .ant-list-item-meta-title {
      color: $primary;
    }
  }
}

.premium-feature-modal {
  .ant-modal-content {
    border-radius: 1rem;
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 4rem;
  }

  .ant-modal-close-x {
    color: black;
  }

  h1 {
    font-size: 22px;
    font-weight: 800;
    margin: 0;
    line-height: 1.2;
    color: black;
  }

  p {
    font-size: 16px;
    margin: 0;
    line-height: 1.2;
    color: black;
  }

  .email-copy {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: fit-content;

    padding: 1rem 2.5rem;
    border-radius: 8px;
    border: 1px solid #94b4ff;
    background: #f3f7fe;
    gap: 1rem;
    cursor: pointer;
    color: #003a93;
    font-size: 19px;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  .img-center {
    margin-inline: auto;
  }
}
