@import "timetablesaves";

$library-card-min-width: 16rem;

.our-library {
  &__modal_wrapper {
    height: 100vh;
    width: 100vw;
    max-width: 100vw;
    margin: 0 !important;
    top: 0;
    padding: 0;

    .ant-modal-content,
    .ant-modal-body {
      height: 100%;
    }

    .ant-modal-body {
      display: flex;
      flex-direction: column;
      padding: 4rem;
      background: #fdfdfd;
    }

    h1 {
      font-size: 1.9em;
      line-height: 1.4;
      text-transform: uppercase;
      font-weight: 900;
      margin-bottom: 0;

      .subtitle {
        font-size: 0.5em;
      }
    }

    h2 {
      margin-top: 1rem;
      font-size: 1.3em;
      line-height: 1.1;
      text-transform: uppercase;
      font-weight: 900;

      .subtitle {
        font-size: 0.6em;
      }
    }

    .subtitle {
      text-transform: uppercase;
      font-weight: 700;
      color: $gray-6;
    }
  }

  &__body {
    display: flex;
    flex-direction: row;
    gap: 3rem;
    flex: 1 1 auto;
    min-height: 0;
    overflow-y: auto;
  }

  &__main_panel {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }

  &__side_panel {
    display: flex;
    flex-direction: column;
    min-width: 30rem;
    max-width: 20%;
    width: fit-content;
    gap: 1rem;
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  &__footer {
    max-height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 2rem;
    gap: 2rem;

    &__buttons {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .ant-btn {
        font-size: 0.8em;

        &-default {
          background-color: white;
          color: $primary;
          border: 1px solid $primary;

          &:hover {
            background-color: $blue-1 !important;
          }
        }
      }
    }
  }

  &__content {
    border: 1px solid #d5d5d5;
    background: white;
    border-radius: 1rem;
    padding: 1.5rem;
    flex: 1 1 auto;
    overflow-y: hidden;

    &,
    & > div {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      min-height: 10rem;
      width: 100%;
    }

    & > * {
      padding-left: 1rem;
    }

    & > div {
      padding: 1rem;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .draggable-save {
      max-height: $save-card-height;
    }

    &__placeholder {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: $gray-6;

      img {
        height: 50%;
      }
    }
  }

  &__button_row {
    display: flex;
    max-height: 4rem;
    height: 4rem;
    flex-direction: row;
    transition:
      max-height 400ms ease-out,
      opacity 500ms ease-in;
    gap: 0.5rem;
    opacity: 1;

    &.hidden {
      max-height: 0;
      opacity: 0;
      transition:
        max-height 400ms ease-out,
        opacity 200ms ease-out;
    }
  }

  &__button_panel {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;

    //display: grid;
    //grid-template-columns: 1fr 1fr;
    transition: all 400ms ease-out;
    gap: 0.9rem 0.5rem;
    flex: 1 1 auto;
    min-height: 0;
    overflow-y: auto;

    font-size: 0.9em;

    &.hidden {
      max-width: 0;
    }
  }

  &__topic_area_button {
    border-color: $primary;
    color: $primary;
    font-size: 0.9em;
    line-height: 1.8;
    height: fit-content;
    min-width: 5rem;

    &:not(.square) {
      border-radius: 2rem;
    }

    span {
      text-transform: uppercase;
    }

    &.current {
      background: $primary;
      color: white;
    }

    &:hover {
      color: white;
      background: black;
    }
  }

  &__topic_button {
    color: #262626;
    font-size: 0.8em;
    line-height: 0.8;
    height: fit-content;
    padding: 7px 10px;
    min-width: 5rem;

    &:focus {
      color: #262626 !important;
    }

    &:not(.square) {
      border-radius: 2rem;
    }

    span {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: uppercase;
    }
  }

  &__starters_container {
    position: relative;
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax($library-card-min-width, 1fr)
    );
    gap: 1rem 1.5rem;
    overflow: visible;

    &__disabled_mask {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: $gray-3;
      opacity: 0.4;
    }
  }

  &__close {
    color: $gray-6;
    font-size: 0.9em;
    text-transform: uppercase;

    .anticon {
      color: black;
      font-size: 1.3em;
      margin-left: 4px !important;
    }
  }

  &__slots {
    flex: 1 1 auto;
    height: 100%;
    min-width: 0;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 2rem;

    &__single {
      flex: 0;
      grid-template-columns: 1fr;
      min-width: min(20rem, 80%);
    }

    > * {
      min-width: 0;
    }

    &__empty {
      width: 100%;
      height: 100%;
      border: 1px dashed #d5d5d5;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #d5d5d5;
    }

    .draggable-save .save-item-fn-row {
      align-items: center;
    }

    .ant-badge-count {
      &:hover {
        background-color: black !important;
      }
    }
  }

  &__quick_search {
    margin: 1rem 0;
  }

  &__difficulty_button {
    text-transform: uppercase;
    font-size: 0.7em !important;
    display: flex;
    align-items: center;
    text-align: center;
    margin: 0;
    height: fit-content;
    cursor: pointer;
    border-radius: 4px;
    font-weight: 700;
    padding: 2px 8px;
    max-width: 100%;
    color: #262626 !important;

    &:hover {
      background-color: black !important;
      color: white !important;
    }
  }
}
