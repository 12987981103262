.close-gap-starter {
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr auto;

  &__drawer {
    .ant-drawer-body {
      padding: 0;
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 0 2rem;
    min-height: 7rem;

    h1 {
      font-family: "recoleta", serif;
      margin-bottom: 0;
      padding: 0.5rem 1rem 0;
      font-size: 29pt;
      line-height: 1;
      font-weight: 700;

      .accent {
        color: $primary;
      }
    }
  }

  &__controls {
    grid-column: 2;
    grid-row: 1 / 3;
    padding: 1.5rem 0.5rem;

    .function-btns {
      display: flex;
      flex-direction: column;
    }
  }

  &,
  &__grid {
    height: 100%;
  }

  &__grid {
    .question-container {
      width: 100%;
      padding: 0;
    }

    .card,
    .flippableCard {
      &.card-h2 {
        height: 50%;
      }

      &.card-w2 {
        width: 50%;
      }

      &.card-w3 {
        width: calc(100% / 3);
      }
    }

    .question-card {
      border-radius: 0;
    }
  }

  .cntdwn-timer {
    border-radius: 0;
    border-width: 1px;
  }
}
.grid-height-toggle-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 3rem;
  margin: -4px auto 18px;

  > *:first-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  > *:nth-child(2) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.grid-height-toggle-container,
.gcse-num-q-toggle-container {
  .ant-btn:not(:disabled) {
    border: 1px solid $primary !important;
  }

  .ant-btn-primary:not(:disabled) {
    &,
    &:hover {
      color: white !important;
      background: $primary !important;
    }
  }

  > * {
    margin: 0 !important;
    padding: 0;
  }
}
