$settingMargin: 1.5rem;

.printModal {
  p {
    margin: 0;
  }

  .ant-modal-body {
    padding-top: 1.5rem;
  }

  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .ant-divider {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }

  .topList {
    border-bottom: 1px solid $gray-4;
  }

  .topRow {
    justify-content: center;
    padding-bottom: 1rem;
    padding-top: 1rem;
    align-items: center;

    -webkit-transition: all 0.4s linear;
    -moz-transition: all 0.4s linear;
    -o-transition: all 0.4s linear;
    transition: all 0.4s ease-out;

    &.show {
      opacity: 1;
    }

    &.hide {
      opacity: 0;
      height: 0;
      padding: 0;
    }

    .ant-alert {
      flex-grow: 2;
      flex-basis: 20%;
    }

    .sideButton {
      white-space: normal;
      flex-basis: 20%;
      height: fit-content;
      margin-left: 2rem;
      flex-grow: 1;
    }

    .ant-btn {
      transition-duration: 0.25s;

      &.noPlenary {
        margin-left: 0;
        margin-top: 1rem;
      }
    }
  }

  .print-mode-toggle {
    width: 100%;

    .ant-radio-group {
      display: flex;

      .ant-radio-button-wrapper {
        flex-grow: 1;
        text-align: center;
      }
    }
  }

  .print-settings {
    margin-left: $settingMargin;
    margin-right: $settingMargin;
    display: block;

    span {
      font-weight: 400;
      margin-bottom: 0;

      &.disabled {
        color: $gray-6;
      }
    }

    li {
      padding: 1rem 0.5rem !important;
      justify-content: space-between;
      width: 100%;
    }
  }

  .printModalFooter {
    justify-content: space-between;

    &,
    &__right {
      display: flex;
      flex-direction: row;
      gap: 1rem;
    }

    &__right {
      justify-self: flex-end;
      justify-content: flex-end;

      .ant-btn:not([disabled]) {
        color: #9553ca;
        border-color: #9553ca;
      }

      .ant-btn-primary:not([disabled]) {
        background-color: #9553ca;
        color: white;
      }
    }
  }
}

.printSettingsSection {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & > .ant-alert {
    margin-bottom: 2rem;
  }

  &Header {
    justify-content: space-between;
  }

  .ant-switch {
    * {
      transition: none;
      animation-duration: 0s;
    }
  }

  .printSettingsControls {
    position: relative;
    overflow: hidden;
    min-height: 0;
    display: flex;
    flex-direction: column;
    gap: 3rem;

    &--loading-overlay {
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 100%;
      background: white;
      opacity: 0.4;
      display: grid;
      place-content: center;
      place-items: center;
    }
  }
}

.print-layout-option {
  border-radius: 1rem;
  border: 1px solid $gray-6;
  padding: 3rem;
  cursor: pointer;
  color: #606060;

  &:hover {
    border-color: $primary;
  }

  &--active {
    border: 2px solid $primary;
    color: rgb(0, 0, 0, 0.85);
  }

  &__title {
    font-weight: 600;
    margin-bottom: 2rem;
    font-size: 2.1rem;

    display: flex;
    justify-content: space-between;
  }

  &__content {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;

    & > * {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 2.5rem;
    }
  }

  .plenaryDateControl {
    max-width: 15rem;
    margin-bottom: 3rem;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.5rem;

    .ant-switch {
      width: 5rem;
    }
  }

  &__settings > div,
  .plenaryDateControl > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__illustration {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    &,
    img {
      width: fit-content;
      height: fit-content;
    }

    img {
      max-width: min(20rem, 100%);
      max-height: 20rem;
    }

    &__caption {
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

$print-margin: 2mm;
$print-subpage-margin: 0;
$cutSpacing: 1em;
$question-font-size: 45px;
$copies-2-scale: 1.5;
$copies-4-scale: 1.87;
$question-border: 1px solid $gray-6;

@media print {
  @page {
    size: A4;
  }

  html,
  body {
    // A4 Size Fallback for browsers which don't support @page (e.g. Safari)
    width: 210mm;
    height: 297mm;
    margin: 0 0 0 0 !important;
    overflow: initial !important;
  }
}

.printedContentHiddenContainer {
  position: absolute;
  z-index: -1;

  width: 3508px;
  height: 2480px;

  &.portrait {
    height: 3508px;
    width: 2480px;
  }
}

.printedContent {
  background: white;

  border: 1px solid transparent; // Needed to prevent random extra page at end
  max-width: 100%;
  max-height: 100%;
  aspect-ratio: 3508 / 2480;

  &.portrait {
    aspect-ratio: 2480 / 3508;
  }

  .printPage {
    margin: $print-margin;
    width: calc(100% - #{$print-margin});
    height: calc(100% - #{$print-margin});
    display: grid;
    grid-gap: $print-subpage-margin;
    --copy-scale: 1;

    &.grid {
      &.copies-1,
      &.copies-2 {
        grid-template-columns: auto;
      }

      &.copies-4 {
        grid-template-columns: auto auto;
      }

      &.copies-2,
      &.copies-4 {
        grid-template-rows: 0.5fr 0.5fr;
      }
    }

    &.strip {
      grid-template-columns: 1fr 1fr;
      grid-auto-flow: column;
    }

    .printedSubPage {
      width: 100%;
      padding: 0 $print-subpage-margin;
      display: flex;
      border: none;
      flex-direction: column;

      &:not(:first-of-type) {
        padding-top: $cutSpacing;
      }

      &:not(:last-of-type) {
        border-bottom: 1px $gray-8 dashed;
        padding-bottom: calc(#{$cutSpacing} + 1mm);
        padding-top: 0;
      }
    }

    &.strip .printedSubPage {
      padding-top: 0;
      padding-bottom: 0;
      border-bottom: none;
      max-width: 100%;

      &:first-of-type {
        border-right: 1px $gray-6 solid !important;
      }
    }

    &.feedbackPage {
      grid-template-columns: 1fr 1fr;

      .printedSubPage {
        border-bottom: none;
        padding-top: 0;
        padding-bottom: 0;
      }

      .feedbackHeader,
      .feedbackBox {
        &:not(:last-of-type) {
          border-bottom: 1px #555555 solid;
        }
      }

      .feedbackHeader {
        padding: 1rem 1rem 0;
        justify-content: center;
        text-align: center;
      }

      .feedbackBox {
        display: flex;
        flex-direction: column;
        padding: 1.5rem;
        flex-grow: 1;
        justify-content: flex-start;
        text-align: start;
        gap: 0.5rem;

        &Title {
          font-weight: bold;
          max-width: 100%;
        }

        &Subtitle {
          font-weight: 700;
        }

        .feedbackStatement {
          flex-direction: row;
          align-items: flex-start;
          gap: 1rem;
          width: fit-content;
          border-bottom: none;
          padding-top: 0.7rem;
          padding-bottom: 0.7rem;

          &,
          span {
            font-size: inherit;
          }

          span {
            line-height: 1em;
            margin-bottom: 0.2em;
          }

          .anticon {
            margin-top: 2px;
          }
        }

        .questionId {
          font-weight: 600;
        }
      }

      .glueLogo {
        width: 8em;
        position: absolute;
        right: 10px;
        height: auto;
      }

      .gluePage {
        flex-grow: 1;
        $glueBackground: $gray-4;
        border-left: 1px $gray-8 dashed;
        background-image: linear-gradient(
          45deg,
          #ffffff 45.45%,
          $glueBackground 45.45%,
          $glueBackground 50%,
          #ffffff 50%,
          #ffffff 95.45%,
          $glueBackground 95.45%,
          $glueBackground 100%
        );
        background-size: 46.67px 46.67px;
        display: flex;
        flex-direction: row;
        text-align: center;
        justify-content: center;
        align-items: center;

        .foldHere {
          position: absolute;
          transform: rotate(90deg) translate(0, calc(25vh - 1em)); // Translation will only be correct under landscape rotation
          color: #8d8d8d;
          font-size: calc(#{$question-font-size} * 1);
        }

        .glueHere {
          color: #8d8d8d;
          font-size: calc(#{$question-font-size} * 1.5);
        }
      }
    }

    &.copies-2,
    &.copies-4 {
      .printedSubPage {
        height: 100%;
      }
    }

    &.copies-1 {
      .questionImage {
        max-height: 600px;
      }
    }

    &.copies-2 {
      .questionImage {
        max-height: 250px;
      }
    }

    &.copies-4 {
      .questionImage {
        max-height: 300px;
      }
    }

    &.landscape {
      transform: rotate(-90deg)
        translate(calc(-100vh + 2 * #{$print-margin}), 0);
      transform-origin: 0 0;
      /* Controls height once rotated */
      width: calc(100vh - 2 * #{$print-margin});
      /* Controls width once rotated */
      height: calc(100vw - 2 * #{$print-margin});
      overflow: hidden;

      // TODO looks like in Chrome for some reason, the maximum vertical height is perhaps being constrained by the page width?
      //      only when there is a page break (so something to do with overflow?)
    }

    .printedHeader {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-content: flex-end;
      align-items: flex-end;
      margin: 1mm 2mm 0.5rem;

      border-bottom: 1px solid $gray-6;

      div {
        padding-left: 0.4em;
        padding-right: 0.4em;
        font-weight: $semibold;
      }

      .titleContainer {
        flex-direction: column;
        align-items: center;
        text-align: center;
      }

      .date,
      .name {
        margin-right: 20%;
      }
    }

    .printedQuestionGrid {
      display: grid;
      grid-gap: 0;
      width: 100%;
      flex-grow: 1;

      &.questions-4,
      &.questions-2 {
        grid-template-columns: 1fr 1fr;
      }

      &.questions-3,
      &.questions-6 {
        grid-template-columns: 1fr 1fr 1fr;
      }

      &.questions-4,
      &.questions-6 {
        grid-template-rows: 1fr 1fr;
      }

      &.questions {
        &-2 {
          .printedQuestionContainer {
            &:nth-child(n + 1) {
              border-left: $question-border;
            }
          }
        }

        &-4 {
          .printedQuestionContainer {
            &:nth-child(-n + 2) {
              border-bottom: $question-border;
            }

            &:nth-child(2n + 1) {
              border-right: $question-border;
            }
          }
        }

        &-3,
        &-6 {
          .printedQuestionContainer {
            &:nth-child(3n + 1),
            &:nth-child(3n-1) {
              border-right: $question-border;
            }
          }
        }

        &-6 {
          .printedQuestionContainer {
            &:nth-child(-n + 3) {
              border-bottom: $question-border;
            }
          }
        }
      }

      .printedQuestionContainer,
      .feedbackContainer {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        align-content: center;
        text-align: center;
        padding-left: 3px;
        padding-right: 3px;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        max-height: 100%;
        width: 100%;

        .calc-icon {
          color: #4a4a4a;
        }

        .title {
          font-weight: $semibold;
          width: 100%;
          border-bottom: 1px solid rgba(160, 160, 160, 0.8);
          height: min-content;
          flex-grow: 0;
          flex-direction: row;
          display: flex;
          align-items: center;
          column-gap: 1rem;
          justify-content: space-between;
          text-align: start;
          padding: 0 0.5rem;
        }

        .questionWrapper {
          display: flex;
          flex-direction: column;
          width: 100%;
          max-height: calc(100% - 3rem);
          flex-grow: 1;
          justify-content: space-between;

          svg {
            max-width: 100%;
          }

          .markingFooter {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: flex-end;
            gap: 1.5rem;
            text-align: end;
            margin-bottom: 0.5rem;
            padding-right: 1.5rem;

            .mark {
              display: flex;
              width: fit-content;
              flex-direction: row;
              gap: 0.5rem;
              align-items: center;

              & > * {
                margin-bottom: 0;
              }
            }
          }
        }

        .questionBody {
          margin-top: 2.5%;
          flex-grow: 1;
          font-weight: normal;
          display: flex;
          align-items: center;
          align-content: center;
          text-align: center;
          flex-direction: column;
        }
      }

      .feedbackContainer {
        flex-direction: column;
        overflow: hidden;
        grid-column: span 2;
        justify-content: left;

        .feedbackLine {
          height: 20px;
          width: 100%;
          flex-grow: 1;
          border-bottom: 1px $gray-6 solid;
        }
      }
    }

    &.strip {
      .printedHeader {
        display: grid;
        grid-template-columns: 1fr 1fr;

        .titleContainer {
          grid-column: span 2;
        }

        .name {
          grid-row: 2;
          margin: 0 0 2rem;
        }

        .date {
          grid-row: 3;
          margin: 0 0 1rem;
        }

        .name,
        .date {
          text-align: left;
          width: 100%;
        }
      }

      .questionWrapper {
        max-width: calc(100% - 15rem);
        margin-left: auto;
        margin-right: auto;

        svg {
          max-width: 100%;
        }
      }

      .printedQuestionContainer {
        position: relative;
        padding-bottom: 2rem;

        border-right: none !important;
        border-bottom: 1px $gray-6 solid;

        .title {
          position: absolute;
          top: 0;
          border-bottom: 0;
        }
      }

      .questionImage {
        max-height: 400px;
      }

      &.qs-6 {
        .questionImage {
          max-height: 260px;
        }
      }

      .printedQuestionGrid {
        display: flex;
        gap: 0;
        width: 100%;
        flex-grow: 1;
        flex-direction: column;
      }
    }

    &.copies-2 {
      --copy-scale: $copies-2-scale;
    }

    &.copies-4 {
      --copy-scale: $copies-4-scale;
    }

    .printedHeader div {
      font-size: calc(#{$question-font-size} * 0.9 / var(--copy-scale));

      &.title {
        font-size: calc(#{$question-font-size} * 1.2 / var(--copy-scale));
      }
    }

    .printedQuestionContainer .title,
    .feedbackBoxSubtitle {
      font-size: calc(#{$question-font-size} * 1.1 / var(--copy-scale));
    }

    .feedbackBox,
    .feedbackBox .ant-list,
    .questionBody,
    .calc-icon,
    .questionImage,
    .markingFooter .mark > * {
      font-size: calc(#{$question-font-size} / var(--copy-scale));
    }

    .feedbackBoxTitle {
      font-size: calc(#{$question-font-size} * 1.2 / var(--copy-scale));
    }
  }
}
