@mixin bgcolours {
  width: 30px;
  height: 30px;
  box-shadow: 1px 2px 3px rgba(160, 160, 160, 0.25);
  border-radius: 50%;
  cursor: pointer;
  margin-left: 1.1em;
}

.bgColours-container {
  display: flex;

  .selected {
    border: $gray-8 solid;
  }

  &.disabled {
    & > div {
      cursor: default;
    }
  }
}

.bgWhite {
  @include bgcolours();
  background: #fff;
  border: 1px solid rgba(192, 192, 192, 0.295);
}

.bgPurple {
  @include bgcolours();
  background: #d5d6ea;
}

.bgBlue {
  @include bgcolours();
  background: #b6d8f2;
}

.bgGreen {
  @include bgcolours();
  background: #d7ecd9;
}

.GS-title {
  background: $pink;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 8px;
  font-weight: 700;
  color: white;
}

.flex-just {
  justify-content: space-around;
  width: 100%;
}

.font-toggle {
  display: flex;
  justify-content: space-evenly;
}

.gridSize-btns {
  display: flex;
  width: fit-content;
  max-height: 4rem;
  overflow: hidden;
}

.customToggleRadioButtons {
  .ant-radio-button-wrapper {
    border: 1px solid $primary;
    height: 100%;
    font-weight: 600;
    transition: all ease-in-out 20ms;
    cursor: pointer;
    align-self: center;
    color: $primary;

    &:first-of-type {
      border-radius: 0.4rem 0 0 0.4rem;
    }

    &:last-of-type {
      border-radius: 0 0.4rem 0.4rem 0;
    }

    &-checked,
    .ant-radio-button-checked {
      color: white !important;
      background: #000;
    }

    &:hover {
      background-color: $primary;
      color: white;
    }
  }
}

.gridSettingsModal {
  .ant-drawer-header {
    align-items: flex-start;
  }

  .ant-modal-body {
    max-height: 54vh;
    overflow-y: auto;
    padding: 0 24px;
  }
}

.grid-settings {
  h3 {
    font-size: 1.8rem;
    font-weight: 400;
    margin-bottom: 0;
  }

  li {
    -webkit-transition: all 0.4s linear;
    -moz-transition: all 0.4s linear;
    -o-transition: all 0.4s linear;
    transition: all 0.4s ease-out;

    &.show {
      opacity: 1;
    }

    &.hide {
      opacity: 0;
      height: 0;
      padding: 0;
    }
  }
}

.senColourContainer {
  .colourSelectorContainer .colourSelector {
    font-size: 2rem;
  }
}

.fontSettingContainer {
  width: fit-content;
  justify-content: flex-end;
  flex-grow: 1;
  gap: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.settings-title {
  font-size: 2.2rem;
  margin-top: 0.5em;
  font-weight: 600;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
  align-items: center;

  .anticon {
    width: 2rem;
    height: auto;
  }

  p {
    margin-bottom: 0;
  }
}

.tagPopoverContainer {
  display: flex;
  flex-wrap: wrap;
}
