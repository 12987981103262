.cueImageContainer {
  background-color: white;
  color: $primary;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 0.5rem;
  cursor: pointer;
  height: 7rem;
  width: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.3rem;
  margin-right: 1rem;

  &.empty {
    border: 1px solid $primary;
    border-radius: 8px;
  }

  img {
    width: 100%;
  }
}

.cueImageDropdown {
  .ant-dropdown-menu {
    max-height: 70vh;
    overflow: auto;

    &-item {
      &:not(:last-of-type) {
        border-bottom: 1px solid #d9d9d9;
      }
    }
  }
}

.cueImageOption {
  max-width: 8rem;
  font-size: 1.4rem;
  text-align: center;
  padding-top: 0.3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 6rem;
  }
}
