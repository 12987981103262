$save-card-height: 86px;
$save-card-margin: 8px;
$save-card-margin-fixed: 16px;
$drawerSaveWidth: 19.5rem;
$column-width: max(15rem, 15vw);
$column-width-modal: max(15rem, calc((80vw - 130px) / 5));

.timetable-section {
  width: 100%;

  .withSa {
    width: calc(100% / 6);
  }
}

.timetable-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;

  &-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: fit-content;
    gap: 1.5rem;

    sup.ant-badge-dot {
      width: 9px;
      height: 9px;
    }
  }

  .timetable-title-text {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    h1 {
      margin-bottom: 0.1em;
      font-weight: 600;
      font-size: 2.4rem;
    }

    p {
      color: $gray-9;
      padding-bottom: 0.2rem;
      margin-bottom: 0;
    }

    @include media("<desktop") {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  @include media("<=tablet") {
    align-items: flex-start;
  }

  @include media("<=phone") {
    flex-direction: column;
  }
}

.timetableControlContainer {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  border: 1px solid #dddddd;
  padding: 0.5rem 1.5rem;
  border-radius: 2rem;
  color: #4e4e4e;
  font-weight: 700;

  &,
  .ant-select-selector {
    border-radius: 2rem !important;
  }

  &.ant-select {
    padding: 0;
    display: block;
  }

  .ant-select {
    &-selection-item,
    &-selection-placeholder {
      line-height: 35px !important;
    }

    &-selector {
      border: none !important;
    }
  }
}

.weekSwitchContainer {
  .ant-switch {
    background: white;

    &,
    &:hover {
      box-shadow: 0 0 0 2px #cdedff;
    }

    &-handle::before {
      background: $primary;
    }
  }

  .ant-click-animating-node {
    box-shadow: unset;
  }

  .selected {
    color: $primary;
  }
}

.savesDrawerSwitchContainer {
  &.open {
    color: $primary;
    border-color: $primary;
  }
}

.timetable-explanation {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  &-link {
    color: $primary;
    width: fit-content;
  }
}

// Timetable ------------------------------------------------------------------------
.timetable {
  flex-basis: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  width: 100%;
  min-width: 0;
  overflow-x: hidden;
  margin: 0 0 0.5em;
  display: grid;

  box-sizing: border-box;
  padding-bottom: calc(1.5 * #{$save-card-margin});

  grid-template-columns: repeat(5, 1fr);

  &.fixed {
    grid-template-columns: min-content repeat(5, 1fr);
  }

  &.hasSaturday {
    grid-template-columns: repeat(6, 1fr);

    &.fixed {
      grid-template-columns: min-content repeat(6, 1fr);
    }
  }

  &.drag {
  }
}

.weekContainer {
  display: flex;
  width: 100%;
  overflow-x: hidden;

  &.drag {
    overflow-x: auto;
  }

  &.drawerOpen {
    overflow: hidden;
  }
}

.timetable-day {
  height: 100%;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1rem 0.75rem 0;
  scroll-snap-align: start;
  flex-shrink: 1;
  min-width: 0;

  &.periods {
    max-width: fit-content;
    min-width: fit-content;
  }

  .placeholder {
    height: calc(2rem * 1.5715); // h3 font size * line height
  }

  h3 {
    text-align: left;
    margin-bottom: 0.3em;

    .titleBadge {
      color: currentColor;
      margin-left: 0.5rem;
      font-size: 1.4rem;
    }
  }

  &.current {
    h3 {
      color: $primary;
    }
  }

  &.drop {
    h3 {
      color: #339b0a;
    }
  }
}

/* ===== Scrollbar CSS ===== */
.weekContainer,
.timetable,
.savesPopupContainer.timetable-section {
  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: $primary $gray-4;

  /* Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;

    &-track {
      background: $gray-4;
    }

    &-thumb {
      background-color: $primary;
      border-radius: 0;
    }
  }
}

.savesDrawer,
.savesDrawItems,
.timetable-day {
  &.drop {
    background: #f0f8fc;
    margin-bottom: 1rem;
  }
}

.dummy-timetable-column {
  height: 0;
}

.timetable-day,
.period-titles {
  h3 {
    font-size: 2rem;
    font-weight: 600;
    color: #a8a8a8;
  }
}

.tagsList {
  .tagWrapper:not(:first-child) {
    margin-top: 0.5rem;
  }
}

.saveItemTag {
  min-width: 7rem;
  text-align: center;
  width: fit-content;
  max-width: 55%;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
}

.period-titles {
  display: inline-grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(8, min-content);
  width: fit-content;
  align-items: center;
  justify-content: center;

  & > *:not(:last-of-type) {
    margin-bottom: $save-card-margin-fixed !important;
  }

  h3 {
    display: flex;
    align-items: center;
    height: $save-card-height;
  }
}

.day-dragable-container {
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
  display: inline-grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(6, min-content);
  column-gap: 0.5rem;

  & > * {
    min-width: 0;
  }

  & > *:not(:last-of-type) {
    margin-bottom: $save-card-margin;
  }

  &.fixed {
    grid-template-rows: repeat(8, min-content);

    & > *:not(:last-of-type) {
      margin-bottom: $save-card-margin-fixed;
    }
  }

  &::-webkit-scrollbar-track {
    background-color: $gray-1;
  }

  &::-webkit-scrollbar {
    width: 0.7rem;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray-5;
    border-radius: 7rem;
  }

  &::-webkit-scrollbar-button:start:decrement,
  ::-webkit-scrollbar-button:end:increment {
    height: 10px;
    display: block;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb:vertical {
    height: 10px;
  }

  &.notesModalColumn {
    .draggable-save {
      width: 100%;
    }
  }
}

.draggable-save {
  width: 100%;
  min-width: 0;
  flex-grow: 1;
  height: $save-card-height;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  padding: 1.1rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &.non_interactive {
    &,
    & * {
      cursor: default;
    }
    [ant-click-animating-without-extra-node="true"]::after {
      display: none;
    }
  }

  &:not(.non_interactive) {
    cursor: pointer;

    &:hover {
      border-width: 2px;
    }

    .save-item-fn-row {
      button,
      .anticon-close {
        &:hover {
          color: $primary !important;
        }
      }
    }
  }

  .save-item-name {
    margin-top: 0.2em;
    text-align: left;
    width: 100%;
    color: $gray-10;
    font-weight: 600;
    font-size: 1.4rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
  }

  .saveItemTag {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &,
    .anticon-close {
      color: #0d0d0d !important;
    }
  }

  .addClassTag,
  .save-item-btns {
    margin: 0;
  }

  .save-item-fn-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    width: 100%;
    column-gap: 0.5rem;

    .saveItemTag,
    .addClassTag {
      flex-grow: 1;
      min-width: 0;
    }

    .anticon-close {
      font-weight: 600 !important;
    }
  }

  .save-item-tag-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100%;
    width: 100%;
    gap: 1rem;

    .ant-tag {
      width: 100%;
      height: 100%;
      color: $primary !important;
      border-color: $primary !important;

      &:hover {
        background: $primary !important;
        color: white !important;
      }
    }
  }

  .save-item-btns {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    button {
      background: none;
      margin: 0;
      padding: 0;
      color: #a8a8a8;
      margin-left: 3px !important;
      margin-right: 3px !important;

      &.addClassTag {
        background: $gray-1;
        padding: 0.1em 0.4em;
        border: 1px solid $gray-6;
        border-radius: 0.3rem;
        color: $gray-9;
        font-size: 1.2rem;
        font-weight: 600;

        &:hover {
          background: $gray-3;
          border-color: $gray-8;
        }
      }
    }
  }
}

.draggable-save-wrapper {
  cursor: pointer;
  transition: transform 300ms ease;

  &.blur {
    opacity: 50%;
    filter: blur(1.25px);
  }

  &.dragging {
    width: fit-content;

    &,
    .draggable-save {
      cursor: grab !important;
      width: 100%;
    }
  }

  .ant-badge {
    width: 100%;
  }
}

// Saves Drawer Styles ----------------------------------------------------------------------------

.savesDrawer {
  p {
    color: $gray-8;
    margin-bottom: 0;
  }

  .ant-drawer-header {
    color: white;
    background: $primary;

    .ant-drawer-header-title,
    .ant-drawer-title,
    .ant-drawer-close {
      color: inherit;
    }

    .ant-drawer-title {
      font-size: 1.7rem;
      font-weight: 900;
    }
  }

  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 0;
    overflow: hidden;

    .savesDrawItems {
      padding-left: 20px;
      padding-bottom: 1em;
      padding-top: 1em;
    }

    p {
      padding-left: 24px;
      padding-right: 24px;
      padding-bottom: 0;
    }
  }
}

.addClassTag,
.addRecallTag,
.draggable-save .saveItemTag {
  height: fit-content;
  font-size: 1.4rem !important;
  border-radius: 4px;
  font-weight: 400 !important;
  padding: 2px 8px;
  max-width: 100%;
}

.addClassTag,
.addRecallTag,
.draggable-save:not(.non_interactive) .saveItemTag {
  cursor: pointer;
}

.addClassTag,
.addRecallTag {
  border: 1px solid $primary;
  background-color: white;
  color: $primary;
  font-size: 1.3rem !important;
  text-align: center;
}

.savesPopupContainerHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 2rem;

  .timetableControlContainer {
    width: fit-content;
  }
}

.savesPopupContainer {
  .day-dragable-container {
    flex-wrap: nowrap;
    max-height: unset;
    display: flex;
    flex-direction: column;
  }

  .weekContainer {
    .timetable {
      width: fit-content;
      scroll-snap-type: unset; // Due to constrained width of modal
    }
  }
}

.newStarterForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.show > * {
    display: unset;
  }

  &:hover {
    cursor: pointer;
    border: 1px solid $primary;
    color: $primary;

    & > * {
      display: unset;
    }
  }

  &Buttons {
    display: grid !important;
    grid-template-columns: 0.9fr 1fr;
    padding: 1em 0.7em;
    gap: 0.5em;
    justify-content: space-around;
    width: 100%;
    height: 100%;

    button {
      width: 100%;
      color: $primary;
      border-radius: 4px;
      font-size: 1.1rem;
      font-weight: 700;
      overflow: hidden;
      padding-left: 4px;
      padding-right: 4px;

      &:first-of-type {
        grid-column: 1 / 3;
      }

      &:last-of-type {
        font-size: 1rem;
      }
    }
  }

  &:not(.show):not(:hover) {
    & > * {
      display: none !important;
    }
  }
}

.emptyTimetableSlot {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: $save-card-height;
  border-width: 1px;
  border-style: dashed;
  border-radius: 8px;
  border-color: #d5d5d5;

  &.drop {
    background: #f0f8fc;
  }
}

.newSaveLocation {
  & > * {
    display: none;
  }

  &.show,
  &:hover {
    cursor: pointer;
    border: 1px solid #2457d6;
    color: rgb(0, 0, 0, 0.85);

    & > * {
      display: unset;
    }
  }

  &.show {
    background: #e9f4fe;
  }

  &:hover {
    border-width: 2px;
    border-color: $primary;
    color: $primary;
  }
}

.dropHere {
  position: absolute;
  padding: 1rem;
}

.savesDrawItems {
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
  width: calc(#{$drawerSaveWidth} + 48px);

  &,
  .droppableArea {
    overflow-x: hidden; //stops horizontal scroll bar when saveItem width > Drawer width
  }

  .day-dragable-container {
    flex-wrap: nowrap;
    display: flex;
    flex-direction: column;
    padding-top: 10px; /* Allows new badge to be visible */

    .ant-badge sup {
      right: 22px !important;
    }
  }

  .droppableArea {
    min-height: 100%;
    width: 100%;
    overflow: hidden;
  }

  .draggable-save {
    width: auto;
    max-width: $drawerSaveWidth;
  }
}

.saveitemoptions {
  display: flex;
  flex-direction: column;
  row-gap: 1em;

  button {
    width: 100%;
  }

  &.ant-btn,
  .ant-btn-sm {
    font-size: 1.3rem;
  }
}
