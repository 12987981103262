//$font: "Proxima Nova", sans-serif;
//$font: "Catamaran", sans-serif;
//$font: "Nunito", sans-serif;
//$font: "Montserrat", sans-serif;
$font: "Nunito Sans", sans-serif;

$yellow: #fdd92a;
$darkblue: #0c42a5;
$pink: #e82e8b;
$semibold: 600;

@import "include-media";
@import "colors";
@import "publichomepage";
@import "trialsignup";
@import "starter";
@import "questions";
@import "sidebar";
@import "gridsettings";
@import "accountInfo";
@import "dashboard";
@import "multiplication";
@import "skillsdrill";
@import "pricingtable";
@import "timetablesaves";
@import "classNotes";
@import "admin";
@import "printed";
@import "cueImages";
@import "freeResources";
@import "ourLibrary";
@import "customButton";
@import "starterTypeModal";
@import "mockPapers";
@import "closeGapStarter";
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap");

@font-face {
  font-family: "recoleta";
  src:
    local("Recoleta-Thin"),
    url(../fonts/Recoleta-Thin.woff2) format("woff2"),
    url(../fonts/Recoleta-Thin.woff) format("woff");
  font-style: normal;
  font-weight: 100;
}
@font-face {
  font-family: "recoleta";
  src:
    local("Recoleta-Light"),
    url(../fonts/Recoleta-Light.woff2) format("woff2"),
    url(../fonts/Recoleta-Light.woff) format("woff");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "recoleta";
  src:
    local("Recoleta-Regular"),
    url(../fonts/Recoleta-Regular.woff2) format("woff2"),
    url(../fonts/Recoleta-Regular.woff) format("woff");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "recoleta";
  src:
    local("Recoleta-SemiBold"),
    url(../fonts/Recoleta-SemiBold.woff2) format("woff2"),
    url(../fonts/Recoleta-SemiBold.woff) format("woff");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "recoleta";
  src:
    local("Recoleta-Bold"),
    url(../fonts/Recoleta-Bold.woff2) format("woff2"),
    url(../fonts/Recoleta-Bold.woff) format("woff");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "recoleta";
  src:
    local("Recoleta-Black"),
    url(../fonts/Recoleta-Black.woff2) format("woff2"),
    url(../fonts/Recoleta-Black.woff) format("woff");
  font-style: normal;
  font-weight: 900;
}

html {
  box-sizing: border-box;
  font-family: $font;
  font-size: 62.5%;
  background: white;
  overflow-y: hidden;

  @include media("<tablet") {
    font-size: 55%;
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  //background: #f6f6f4;
  margin: 0;
  font-family: $font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app {
  position: relative;
  overflow: hidden;
}

#mobile-intercept {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: white;
  z-index: 99999999;

  @media (min-width: 576px) {
    display: none;
  }

  h1 {
    font-size: 3rem;
    line-height: 3.5rem;
    font-weight: 900;
  }

  h2 {
    font-size: 2.2rem;
    line-height: 2.8rem;
    font-weight: 900;
    color: $primary;
    margin-bottom: 2rem;
  }

  p,
  span {
    font-size: 2rem;
    line-height: 2.3rem;
    margin-bottom: 1rem;
  }

  a {
    font-size: 2rem;
    line-height: 1.6;
  }

  .img-container {
    width: 100%;
    margin-bottom: 4rem;

    img {
      width: 100%;
      height: auto;
    }
  }

  .text-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0 5rem;
    margin-bottom: 4rem;
    text-align: center;

    &:last-of-type {
      margin-bottom: 0;
      margin-top: 6rem;
      padding-bottom: 6rem;
      border-bottom: #005ef0 1.5rem solid;
      flex-grow: 1;
    }
  }

  .videoContainer {
    height: fit-content;
    aspect-ratio: 4/3;
  }
}

/* Drip form generated styling */
form#drip-ef-670371684 {
  font-size: 2rem;
  line-height: 2.9rem;
  width: 100%;
  margin-top: -1rem;

  h3 {
    font-size: 1.125rem;
    margin: 0 0 0.375rem;
  }

  & > div {
    margin-bottom: 0.75rem;
    display: flex;
    flex-direction: column;
  }

  fieldset {
    border: none;
    margin: 0;
    padding: 0;
  }

  label {
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: #1c1c1c;
    font-weight: 700;
  }

  legend {
    margin: 0;
    padding: 0;
  }

  input[type="email"],
  input[type="number"],
  input[type="tel"],
  input[type="text"] {
    padding: 0.375rem 0.5625rem;
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    margin: 0 0 1rem;
    border-width: 1px;
  }

  input[type="checkbox"],
  input[type="radio"] {
    margin: 0.1875rem 0.1875rem 0 0;
    padding: 0;
  }

  input[type="submit"] {
    margin: 0;
    padding: 0.8rem 1.5rem;
    background: #0058de;
    color: white;
    font-weight: 800;
    font-size: 17px;
    border: 0;
    border-radius: 4px;
  }
}

.app-container {
  display: flex;
  height: 100vh;
  font-family: $font;

  transition: height ease-in-out 0.3s;

  &.tourOpen {
    height: calc(100vh - 12rem) !important;
  }
}

button {
  cursor: pointer;
  border: 0;
  font-family: $font;
}

.noBottomMargin {
  margin-bottom: 0 !important;
}

h1,
h2,
h3,
h4,
p {
  font-family: $font;
}

h1 {
  font-weight: 700;
  font-size: 2.2rem;
}

p {
  font-weight: 400;
  font-size: 1.6rem;
}

.semibold {
  font-weight: $semibold;
}

input {
  font-family: $font;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

.ant-form-inline .ant-form-item-with-help {
  margin-bottom: 0;
}

.popoverForm {
  .ant-row:last-child {
    margin-bottom: 1rem;
  }
}

a {
  text-decoration: none !important;
}

.clickable {
  cursor: pointer;
}

.anticon {
  vertical-align: middle !important;
}

.ant-modal-title {
  .anticon {
    padding-right: 1rem;
  }
}

.warning404 {
  margin-top: 7rem;
  width: 100%;

  .ant-result-title {
    font-size: 3.5rem;
  }

  .ant-result-subtitle {
    font-size: 2.1rem;
  }
}

.starterError {
  max-width: 70%;
  margin-left: auto;
  margin-right: auto;

  .ant-result-title {
    font-size: 2.7rem;
  }

  .ant-result-subtitle {
    font-size: 2rem;
  }
}

.warning-btn {
  color: $gray-11;
  background: $gold-5;
  border-color: $gold-5;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);

  &:hover {
    color: $gray-11;
    background: $gold-4;
    border-color: $gold-4;
  }
}

// Highlight span in Cascader
.ant-cascader-menu-item-keyword {
  display: contents;
}

.loadingContainer {
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

// Tour popover
.reactour__helper {
  border-radius: 8px !important;

  p:not(:last-child) {
    margin-bottom: 0.5em;
  }

  p:last-child {
    margin-bottom: 0;
  }

  .dummyButton {
    color: #000000d9 !important;
    cursor: default !important;
    background: white !important;
    border-color: #d9d9d9 !important;
  }

  .ant-list-item {
    padding: 1rem 0;

    .anticon {
      margin-right: 1rem;
    }
  }

  .tourGifContainer {
    width: 100%;
    display: flex;
    justify-content: center;

    img {
      max-width: 80%;
    }

    &.small img {
      max-width: 40%;
    }
  }
}

// Tour mask SVG
.tourMask {
  color: black;
  opacity: 0.53;
}

// Tour exit buttons div
.tourExitContainer {
  position: absolute;
  top: 30px;
  width: 200px;
  border-radius: 8px;
  z-index: 1000000;
  height: fit-content !important;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  background: white;
  padding: 1.5rem;
}

// Footer container for tour steps
.tourStepsContainer {
  position: absolute;
  bottom: 0;
  padding: 1.3rem 2.5rem 1.9rem;
  margin-left: 11.5rem; // Max sidebar width + 1.5
  width: calc(100vw - 12.5rem);
  z-index: 1000000;
  height: fit-content !important;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: white;

  .ant-steps-item-title {
    font-size: 1.5rem;
  }

  .ant-steps-item-description,
  p {
    font-size: 1.35rem;
  }

  &.fullscreen {
    margin-left: 0;
    width: 100vw;
  }
}

// Tour Next/Back buttons container
.tourButtons {
  text-align: right;
  margin-top: 0.5rem;
  display: flex;
  justify-content: flex-end;

  &.hasBack {
    justify-content: space-between;
  }

  .tourBackButton {
    opacity: 0.8;

    &:hover {
      color: blue;
      opacity: 1 !important;
    }
  }
}

.external-resources-modal {
  .ant-modal-content {
    border-radius: 1rem;
  }
  .ant-modal-body {
    padding: 5rem 4rem;
  }
  .ant-table-thead > tr > th {
    font-weight: 700;
  }
}

.icon-only-button {
  display: grid;
  place-content: center;
  place-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;

  &:is(a).ant-btn {
    padding-top: 1rem !important;
  }
}
