.publicResources {
  &Container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 3rem;
  }

  &Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 0 0;

    &Logo {
      flex-grow: 1;
      max-width: 12rem;
    }

    &Menu {
      display: flex;
      flex-direction: row;
      gap: 2em;
      font-size: 1.5rem;
      font-weight: 800;

      p,
      a {
        font-size: inherit;
        font-weight: inherit;
        color: black;

        &.current {
          color: $primary;
        }

        &.disabled {
          color: $gray-6;
          cursor: not-allowed;
        }
      }
    }
  }

  &ActivityContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-height: calc(100% - 5rem);
    margin-top: 2rem;

    &.fullscreen {
      max-height: 100%;
      padding-bottom: 4rem;
    }
  }
}
