//$class-tag-height: 4.5rem;
$class-tag-margin: 1em;

.dashboard-container {
  width: 100%;
  height: 100vh;
  margin-left: auto; // For saves draw animation
  display: flex;
  padding: 0.75em 2.2em 2em 2.2em;
  flex-direction: column;
  background: #fff;
  overflow-y: auto;
  transition: width 0.3s cubic-bezier(0.7, 0.3, 0.1, 1) 0s;

  &.savesOpen {
    width: calc(100vw - 245px); // minus saves drawer width (245)
  }

  @include media("<=tablet") {
    padding: 1em 2em;
  }
}

// Dashboard Page Bar {Greeting, Trial Alert & Settings Btn} -------------------------

.dashboard-welcome {
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 1rem;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 20px;

  @include media("<=1100px") {
    flex-direction: column;
  }

  .greeting {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-top: 1em;
    flex-grow: 1;

    @include media("<desktop") {
      flex-direction: column;
      align-items: center;
    }

    .trialAlert {
      margin-right: 2rem;
      flex-basis: 30%;
      text-align: center;
    }

    .greetingText {
      display: flex;
      flex-direction: row;
      align-items: flex-end;

      @include media("<desktop") {
        flex-direction: column;
        align-items: center;
      }
    }

    h1 {
      font-weight: 600;
      font-size: 3.2rem;
      padding-right: 0.4em;
      line-height: 4rem;
      white-space: nowrap;
    }

    p {
      font-size: 2.4rem;
      font-weight: 500;
      line-height: 2.5rem;
      margin-right: 2rem;
      text-align: center;
      @include media("<=tablet") {
        margin-right: 0;
      }
    }
  }
  .dashsetbtn {
    @include media("<desktop") {
      margin-top: 1em;
    }
  }
}

.dashboard-settings-btn {
  background: transparent !important;
  display: grid;
  justify-content: center;
  align-items: center;

  &,
  &:active {
    color: #a8a8a8;
  }

  &:hover {
    color: $primary;
  }

  svg {
    font-size: 2.25rem;
  }
}

// Wrapper for timetable, quick starter, class tags & metric cards
.dashboard-items {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 0.5em;
}

// Dashboard Section (New Starter, Your Classes, Your Stats)  -----------------------------------------
.dashSection-wrap {
  display: flex;
  column-gap: 2em;
  margin-top: 2.3em;
  align-items: stretch;

  @include media("<desktop") {
    flex-direction: column;
    row-gap: 4em;
  }

  .dashSection {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    // &:nth-child(1) {
    //   flex-grow: 2;
    // }

    h1 {
      margin-right: 0.75em;
      font-size: 2.4rem;
      font-weight: 600;
      margin-bottom: 0.1em;
    }

    p {
      color: $gray-9;
      padding-bottom: 0.2rem;
    }

    .dashCard {
      background: $gray-1;
      border-radius: 0.8rem;
      border: 1px solid #cbcbcb;
      flex-grow: 1;
      padding: 2.4rem;
    }

    &.newstc {
      @include media(">1024px") {
      }
    }

    &.tagsStats {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      column-gap: 2em;

      @include media("<1100px") {
        flex-direction: column;
        row-gap: 3em;
        padding: 1.5em 0;
      }
    }
  }
}

.emptyDashboardIcon {
  max-width: 15rem;
  align-self: center;
  margin: 5rem;
}

// New starter buttons (found on dashboard and new starter modal) -------------

.newstarterbtns {
  display: flex;
  align-items: center;
  justify-content: center;

  @include media(">=desktop", "<=1300px") {
    flex-direction: column;
    align-content: center;
    row-gap: 0.5em;
  }

  @include media("<=tablet") {
    flex-direction: column;
    align-content: center;
    row-gap: 0.5em;
  }

  p {
    padding: 0 1.2em;
    margin: 0;
    font-weight: $semibold;
    font-size: 1.8rem;
  }

  button {
    white-space: normal;
    height: auto;
  }
}

// Our Library ---------------------------------------------------------------

.ourlibrary {
  margin-top: 2em;

  .libraryHeader {
    display: flex;
    flex-grow: 1;
    margin-bottom: 1.5em;

    h3 {
      flex-grow: 1;
      width: 100%;
    }
  }

  .libraryItem {
    display: flex;
    flex-grow: 1;
    padding: 0.4em 0;

    p {
      flex-grow: 2;
      font-weight: $semibold;
      color: $gray-10;
      margin: 0;
    }
  }
}

/// Class Tags ---------------------------------------------------------------

.classListModal {
  .ant-modal-title {
    font-weight: 700;
  }

  .ant-modal-close-x {
    color: black;
    font-size: 0.9em;
  }

  .ant-modal-header {
    border-bottom: none;
  }

  .ant-modal-content {
    border-radius: 1rem;
    overflow: hidden;
  }

  .ant-modal-body {
    max-height: 70vh;
    display: flex;
    flex-direction: column;
    padding-top: 0.5rem;
  }
}

.classListModalWrapper {
  overflow-y: auto;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-top: 3rem;
}

.newClassForm {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1.5rem 2rem;
  background-color: #f9f9f9;
  border: 1px solid #eeeeee;
  border-radius: 1rem;

  &__row {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
    gap: 1rem;

    & :first-child {
      flex-grow: 1;
    }
  }

  label {
    font-size: 0.9em;
  }

  button {
    border-radius: 0.5rem;
  }
}

.classTags {
  display: flex;
  flex-direction: column;
  row-gap: 2em;

  .classList {
    flex-grow: 1;
    display: inline-grid;
    row-gap: $class-tag-margin;
    column-gap: calc(0.7 * #{$class-tag-margin});
    grid-auto-flow: row;
    grid-template-columns: 1fr;
    min-width: max(15rem, 16vw);
  }

  .classListItem {
    display: flex;
    width: 100%;
    justify-content: space-between;
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;
    padding: 8px 16px;
    //height: $class-tag-height;#
    cursor: pointer;

    p {
      margin: 0;
      font-weight: 600;
      color: #000;
    }

    .classListDelete {
      color: #a8a8a8;
    }
  }
}

.newClassForm {
  .ant-form-item {
    margin-bottom: 0.5rem;
  }
}

.savesPopupContainer,
.timetable .ant-card-body {
  flex-direction: row;
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
  flex-grow: 1;
  flex-wrap: wrap;
  overflow: auto;
}

.savesPopupContainer {
  max-height: 60vh;

  section {
    margin-top: 0;
  }

  &.timetable-section {
    margin-top: 0 !important;
  }
}

// ============= Metrics ================================

.dashCardsContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.dashboardMetricsContainer {
  text-align: center;
  align-items: center;
  flex-grow: 0 !important;

  .hoursContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 1rem;

    .hours {
      display: flex;
      flex-direction: row;
      color: #47950b;
      font-size: 4.6rem;
      justify-content: center;
      font-weight: bold;

      .fraction {
        margin-left: 0.5rem;
        display: flex;
        flex-direction: column;
        font-size: 2.4rem;
        align-items: center;
        text-align: center;

        .denominator {
          text-decoration: overline;
        }
      }
    }

    svg {
      height: 64px;
      width: auto;
    }

    h4 {
      color: #0d0d0d;
      margin-bottom: 0;
    }
  }

  .feedbackButtons {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: flex-start;

    button,
    a {
      width: 100%;
    }

    .outlinedButton {
      outline: 1px solid $primary !important;
      color: $primary;
    }
  }
}

/** Dashboard Settings Modal **/

.dashboard-toggle {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;

  h3 {
    font-size: 1.8rem;
    font-weight: 400;
  }
}

.dash-set-title {
  font-weight: 600;
  font-size: 17px;
}

.newSaveInfo {
  &.ant-notification-notice {
    border-radius: 1rem;
    width: fit-content;
    max-width: 95vw;
    padding: 2.5rem 3rem 3rem;
    background: #e9f4fe;
    border: 1px solid #2457d6;
  }

  .ant-notification-notice-with-icon > * {
    margin-left: 0 !important;
  }

  .ant-notification-notice-message {
    font-size: 1.2em;
    font-weight: 600;
    margin-bottom: 0.25rem;
  }

  .ant-notification-notice-description {
    & > * {
      font-size: 0.9em;
    }
  }

  p {
    margin-bottom: 1rem;
  }

  a {
    text-decoration: underline !important;
  }

  .ant-btn {
    border-radius: 0.75rem;
  }
}
