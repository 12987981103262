.skills {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100%;

  &-header {
    margin-top: 2rem;

    &,
    &-group {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 2rem;
      flex-wrap: wrap;
    }

    h1 {
      font-size: 4rem;
      font-weight: 900;
      line-height: 1;
      margin-bottom: 0;
      margin-right: 1rem;
    }

    .ant-btn.reload {
      margin-left: -4px;

      svg {
        font-size: 2.5rem;
      }
    }
  }

  &-content {
    $sidePad: 2rem;

    flex: 1 1 0;
    max-height: 70rem;
    background: white;
    padding: 3rem $sidePad;
    border: #1660e7 solid 1px;
    border-radius: 16px;
    margin-bottom: 3rem;
    overflow-y: auto;
    position: relative;

    ol {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 1rem;
      flex-grow: 1;
      height: 100%;
      margin-bottom: 0;

      li {
        margin-left: $sidePad;
        padding-left: 1rem;

        &::marker {
          font-weight: 800;
        }
      }
    }

    .cntdwn-timer {
      position: absolute;
      top: $sidePad;
      right: $sidePad;

      border-color: #f2b61a;
      background: #ffe55c1c;
    }
  }
}
