/* ------------------------ Public-facing Page and Login ----------------------------*/

.wrap {
  background: white;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  overflow-y: auto;

  &.grey {
    background: #f9f9f9;
  }

  .container-home {
    display: flex;
    padding-right: 0;
    padding-left: 0;
    width: 100%;
    height: 100%;
    max-width: 2000px;
    margin: 0 auto;

    @include media("<=tablet") {
      flex-direction: column;
    }

    .content-hero-wrapper {
      align-items: center;
      background: #0058de;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      width: 45%;

      .container-splash {
        width: 75%;
      }
    }

    .content-container-login {
      align-items: center;
      background: #fff;
      display: flex;
      flex-direction: row;
      height: 100%;
      margin-bottom: 3rem;
      padding-top: 5em;
      width: 55%;

      .login-form {
        margin-left: 25%;
        width: 46%;
      }
    }

    .container-left {
      width: 45%;
      background: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      align-items: center;
      padding: 4rem;

      @include media("<=tablet") {
        width: 100%;
        height: fit-content;
      }
    }

    .container-splash {
      width: fit-content;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 3rem;
      margin: 0 auto;

      @include media("<=tablet") {
        padding: 2em 0;
      }

      @include media(">=phone", "<tablet") {
        width: 80%;
      }

      > img {
        width: 185px;
        max-width: 100%;

        &.full-width {
          width: 100% !important;
          align-self: center;
          max-width: 40rem;
        }

        @include media(">=phone", "<tablet") {
          width: 150px;
        }
      }
    }

    .container-right {
      width: 55%;
      background: $primary;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-top: 5em;
      padding-bottom: 5em;

      @include media("<=tablet") {
        width: 100%;
      }
    }
  }

  .hero {
    max-width: min(90%, 45rem);
    width: fit-content;
    margin: 0 auto;
    background: white;
    border-radius: 20px;
    height: fit-content;
    padding: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > img {
      width: 100%;
      margin-left: 4px;
    }

    h2 {
      max-width: 40rem;
      font-weight: normal;
      font-size: 2.2rem;
      line-height: 3rem;
      color: black;
      text-align: center;
      margin-top: 2rem;

      @include media("<=tablet") {
        font-size: 1.9rem;
        line-height: 2.8rem;
      }

      @include media(">=phone", "<tablet") {
        font-size: 1.5rem;
        line-height: 2.2rem;
      }
    }
  }

  .logo-home {
    max-width: 150px;
  }

  .content-icons {
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    width: 100%;
    justify-content: space-between;

    img {
      flex-grow: 1;
      max-width: 14rem;
    }
  }

  .action-btns {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    p {
      color: $gray-5;
    }

    a,
    button {
      border-radius: 0.2rem;
      margin-right: 1.4rem;
    }

    .buttons {
      display: flex;
      flex-direction: row;
      margin-top: 0.5rem;
      margin-bottom: 0.8em;
    }

    .button-desc {
      display: flex;
      flex-direction: row;
      margin-top: 0.5rem;

      @include media(">=phone", "<tablet") {
        display: none;
      }

      .semibold {
        font-weight: $semibold;
        margin-right: 0.5rem;
      }
    }
  }

  .login-form {
    h2 {
      font-weight: $semibold;
      font-size: 2.7rem;
      line-height: 3rem;

      @include media(">=phone", "<tablet") {
        font-size: 2.4rem;
      }
    }

    a {
      color: $gray-7;
    }

    .login-subtitle {
      a {
        color: $blue-6;
      }
    }
  }

  .home-btn {
    border: 0;
    max-width: 300px;
    font-size: 19px;
    font-weight: 600;
    background: white;
    font-family: "Roboto", sans-serif;
    color: #000;
    border-radius: 20px;
    width: 90%;
    margin: 10px 0;
    cursor: pointer;
    height: 3rem;
  }
}

.splash-primary {
  color: $gray-10;
  background: $lime-4;
  border: 0;
  padding: 0.5em 0.7em;
  font-weight: 600;
  font-size: 1.8rem;

  @include media(">=phone", "<tablet") {
    font-size: 1.4rem;
  }

  &:hover {
    background: $gray-10;
    color: $gray-1;
  }
}

.splash-secondary {
  color: $gray-1;
  background: $blue;
  padding: 0.5em 0.7em;
  font-weight: 600;
  font-size: 1.8rem;
  border: 1px solid $gray-1;

  @include media(">=phone", "<tablet") {
    font-size: 1.4rem;
  }

  &:hover {
    background: $gray-10;
    color: $gray-1;
    border-color: $gray-10;
  }
}

.formButtons {
  text-align: end;
  margin-bottom: 0;

  .ant-spin {
    margin-right: 3rem;
  }

  button {
    margin-left: 1rem;
  }
}

.requestResetForm,
.createAccountForm,
.createAccountSwitch {
  label {
    min-width: 150px;
  }

  .ant-form-item-control > .ant-form-item-explain {
    padding: 1rem;
  }

  .passwordValidation {
    ul {
      margin-left: 2rem;
      margin-bottom: 0;

      li {
        list-style: none;
      }
    }
  }
}

.createAccountContainer {
  .stepsHeader {
    width: 100%;
  }
}

.createAccountForm,
.createAccountSwitch {
  .ant-form-item {
    margin-bottom: 2em;
  }

  .schoolSelect {
    .ant-select-clear {
      font-size: 18px;
      line-height: 1.5rem;
      top: 42%;
      right: 14px;
    }
  }

  .ant-form-item-extra,
  .ant-form-item-explain {
    margin-top: 0.5rem;
  }
}

.signInForm {
  label {
    font-size: 1.9rem;
    font-weight: $semibold;

    @include media(">=phone", "<tablet") {
      font-size: 1.6rem;
    }
  }
}
